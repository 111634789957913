import { useState } from "react";
import * as XLSX from 'xlsx';
import NavbarMenu from './NavbarMenu';
import { Container, Table, ListGroup, Row, Col, Spinner } from 'react-bootstrap';

function UploadBulkData() {
  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  // submit state
  const [excelData, setExcelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [duplicateRows, setDuplicateRows] = useState([]);
  const [duplicateColumns, setDuplicateColumns] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);


  const postDataToServer = (data) => {
    // console.warn(data);
    const semis_code = JSON.parse(localStorage.getItem('userin'));
    setIsLoading(true);
    fetch(`https://stddataapi.rsu-sindh.gov.pk/api/addstudentfromexcel/${semis_code}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ dataArray: data }),
    })
      .then((result) => {
        result.json().then((response) => {
          setIsLoading(false);
          let duplicateRowKey;
          if (response.status === 'invalid') {
            alert(`Invalid SEMIS Code, User's SEMIS CODE & in EXCEL not same`);
          }
          if (response.status === 400) {
            // console.log(response);
            duplicateRowKey = response.duplicateRowKey; // Assuming the server sends the duplicate row key in the response
            // console.log(duplicateRowKey);
            alert(`Duplicate column in row ${duplicateRowKey}: Duplicate entry check Student GR NO. or B-Form for the same School`);
          }
          else if (response.status === 200) {
            alert('Student data inserted successfully!');
          } else {
            alert("Error occurred while processing the request!");
          }
        })
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error show', error);
        alert("Something went wrong!");
      });
  };



  // onchange event
  const handleFile = (e) => {
    let fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        }
      }
      else {
        setTypeError('Please select only excel file types');
        setExcelFile(null);
      }
    }
    else {
      // console.log('Please select your file');
    }
  }




  const handleFileSubmit = (e) => {
    e.preventDefault();
    // Reset previous errors
    setTypeError(null);
    setDuplicateRows([]);
    setDuplicateColumns([]);
    setValidationErrors([]);
    setIsLoading(true);
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: 'buffer' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const originalData = XLSX.utils.sheet_to_json(worksheet, {
        blankRows: true,
        defval: '',
        raw: false,
        dateNF: 'mm-dd-yyyy',
        cellDates: true,
      });

      const validationErrors = [];
      originalData.filter((row, rowIndex) => {
          const rowValidationErrors = [];
      
          const semisCode =  row.SEMIS_CODE !== undefined && row.SEMIS_CODE.length === 9;
          if (!semisCode) {
              rowValidationErrors.push(`SEMIS Code column must not be empty and must be a 9-digit code`);
          }

          console.log('semisCode:', semisCode);
      
          const semisCodeIsValid = (row.SEMIS_CODE != '' && /^[0-9]{9}$/.test(row.SEMIS_CODE)) || row.SEMIS_CODE == '';
          if (!semisCodeIsValid) {
              rowValidationErrors.push(`SEMIS Code is not valid`);
          }
          console.log('semisCodeIsValid:', semisCodeIsValid);
      
          const SchoolName = row.SCHOOL_NAME !== '';
          if (!SchoolName) {
              rowValidationErrors.push(`School Name column must not be empty`);
          }
          console.log('SchoolName:', SchoolName);
      
          const SchoolDistrict = row.DISTRICT !== '' && /^[A-Za-z\s]+$/.test(row.DISTRICT);
          if (!SchoolDistrict) {
              rowValidationErrors.push(`District column must not be empty and must be in alphabet`);
          }
          console.log('SchoolDistrict:', SchoolDistrict);
      
          const SchoolTaluka = row.TALUKA !== '';
          if (!SchoolTaluka) {
              rowValidationErrors.push(`Taluka column must not be empty`);
          }
          console.log('SchoolTaluka:', SchoolTaluka);
      
          const SchoolUC = row.UNION_COUNCIL !== '';
          if (!SchoolUC) {
              rowValidationErrors.push(`UNION COUNCIL column must not be empty`);
          }
          console.log('SchoolUC:', SchoolUC);
      
          const SchoolLEVEL = row.LEVEL !== '' && /^[A-Za-z\s]+$/.test(row.LEVEL);
          if (!SchoolLEVEL) {
              rowValidationErrors.push(`School Level column must not be empty and must be in alphabet`);
          }
          console.log('SchoolLEVEL:', SchoolLEVEL);
      
          const SchoolGENDER = row.GENDER !== '' && /^[A-Za-z\s]+$/.test(row.GENDER);
          if (!SchoolGENDER) {
              rowValidationErrors.push(`School GENDER column must not be empty and must be in alphabet`);
          }
          console.log('SchoolGENDER:', SchoolGENDER);

          const isValidateSTUDENT_GENDER = row.STUDENT_GENDER !== '' && /^[A-Za-z\s]+$/.test(row.STUDENT_GENDER);
          if (!isValidateSTUDENT_GENDER) {
              rowValidationErrors.push(`School GENDER column must not be empty and must be in alphabet`);
          } else {
              // Additional check for valid values "Boy" or "Girl"
              const validGenders = ['Boy', 'Girl'];
              if (!validGenders.includes(row.STUDENT_GENDER)) {
                  rowValidationErrors.push(`Invalid value in SCHOOL GENDER column. Allowed values are "Boy" or "Girl"`);
              }
          }
          console.log('STUDENT_GENDER:', isValidateSTUDENT_GENDER);

          const validGrades = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "Katchi", "ECE"];
            const isValidateGRADE = row.GRADE !== '' && validGrades.includes(row.GRADE);

            if (!isValidateGRADE) {
                rowValidationErrors.push(`Student GRADE column must not be empty and should be one of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "Katchi", "ECE"]`);
            }

            console.log('validGrades:', isValidateGRADE);

          // const isValidateSTUDENT_WITH_DISABILITY = row.STUDENT_WITH_DISABILITY !== '' && /^[A-Za-z\s]+$/.test(row.STUDENT_WITH_DISABILITY);
          // if (!isValidateSTUDENT_WITH_DISABILITY) {
          //     rowValidationErrors.push(`School GENDER column must not be empty and must be in alphabet`);
          // } else {
          //     // Additional check for valid values "Boy" or "Girl"
          //     const validGenders = ['Yes', 'No'];
          //     if (!validGenders.includes(row.STUDENT_WITH_DISABILITY)) {
          //         rowValidationErrors.push(`Invalid value in is STUDENT WITH any DISABILITY column. Allowed values are "Yes" or "No"`);
          //     }
          // }

          const StudentSTUDENT_LANGUAGE = row.STUDENT_LANGUAGE !== '' && /^[A-Za-z\s]+$/.test(row.STUDENT_LANGUAGE);
          if (!StudentSTUDENT_LANGUAGE) {
              rowValidationErrors.push(`Student LANGUAGE column must not be empty and must be in alphabet`);
          }
          console.log('StudentSTUDENT_LANGUAGE:', StudentSTUDENT_LANGUAGE);

          const StudentGUARDIAN_RELATION = row.GUARDIAN_RELATION !== '' && /^[A-Za-z\s]+$/.test(row.GUARDIAN_RELATION);
          if (!StudentGUARDIAN_RELATION) {
              rowValidationErrors.push(`Student Parent/Guardian RELATION column must not be empty and must be in alphabet`);
          }
          console.log('StudentGUARDIAN_RELATION:', StudentGUARDIAN_RELATION);
      
          const isValidateSTUDENT_WITH_DISABILITY =
            row.STUDENT_WITH_DISABILITY !== '' &&
            /^[A-Za-z\s]+$/.test(row.STUDENT_WITH_DISABILITY) &&
            ['Yes', 'No'].includes(row.STUDENT_WITH_DISABILITY);

          if (!isValidateSTUDENT_WITH_DISABILITY) {
            rowValidationErrors.push(
              `Student with DISABILITY column must not be empty, must be in alphabet, and must be either "Yes" or "No"`
            );
          }
          console.log('STUDENT_WITH_DISABILITY:', isValidateSTUDENT_WITH_DISABILITY);

          // const StudentSTUDENT_WITH_DISABILITY = row.STUDENT_WITH_DISABILITY !== '' && /^[A-Za-z\s]+$/.test(row.STUDENT_WITH_DISABILITY);
          // if (!StudentSTUDENT_WITH_DISABILITY) {
          //     rowValidationErrors.push(`Student with DISABILITY column must not be empty and must be in alphabet`);
          // }
          // console.log('StudentSTUDENT_WITH_DISABILITY:', StudentSTUDENT_WITH_DISABILITY);
      
          const StudentSTUDENT_RELIGION = row.STUDENT_RELIGION !== '' && /^[A-Za-z\s]+$/.test(row.STUDENT_RELIGION);
          if (!StudentSTUDENT_RELIGION) {
              rowValidationErrors.push(`Student RELIGION column must not be empty and must be in alphabet`);
          }
          console.log('StudentSTUDENT_RELIGION:', StudentSTUDENT_RELIGION);
      
          const StudentDATE_OF_ENROLMENT = row.DATE_OF_ENROLMENT !== '';
          if (!StudentDATE_OF_ENROLMENT) {
              rowValidationErrors.push(`Student DATE OF ENROLMENT column must not be empty`);
          }
          console.log('StudentDATE_OF_ENROLMENT:', StudentDATE_OF_ENROLMENT);
      
          // const StudentSECTION = row.SECTION !== '' && /^[A-Za-z\s]+$/.test(row.SECTION);
          // if (!StudentSECTION) {
          //     rowValidationErrors.push(`Student SECTION column must not be empty and must be in alphabet`);
          // }

           const validSections = ['A', 'B', 'C', 'D', 'E', 'F'];
            const StudentSECTION = row.SECTION !== '' && /^[A-Fa-f\s]+$/.test(row.SECTION) && validSections.includes(row.SECTION.toUpperCase());

            if (!StudentSECTION) {
                rowValidationErrors.push(`Student SECTION column must not be empty and must be one of ["A", "B", "C", "D", "E", "F"]`);
            }
 

          console.log('StudentSECTION:', StudentSECTION);
      
          const StudentDATE_OF_BIRTH = row.DATE_OF_BIRTH !== '';
          if (!StudentDATE_OF_BIRTH) {
              rowValidationErrors.push(`Student DATE OF BIRTH column must not be empty`);
          }
          console.log('StudentDATE_OF_BIRTH:', StudentDATE_OF_BIRTH);
      
          const isValidStudentGRNO = row.STUDENT_GRNO !== '' && /^\d+$/.test(row.STUDENT_GRNO);
          if (!isValidStudentGRNO) {
              rowValidationErrors.push(`Student GR NO. must be a numeric value and must not be empty`);
          }
          console.log('isValidStudentGRNO:', isValidStudentGRNO);
      
          const isValidBForm = (row.IS_BFROM_AVAILABLE === 'Yes' && row.STUDENT_BFORM.length > 0) || (row.IS_BFROM_AVAILABLE === 'No' && row.STUDENT_BFORM.length === 0);
          if (!isValidBForm) {
              rowValidationErrors.push(`If B-Form available value is "Yes" then Student B-Form column must not be empty`);
          }
          console.log('isValidBForm:', isValidBForm);
      
          const yesValidGuardianCNIC = (row.IS_FATHER_GAURDIAN_CNIC_AVAILABLE === 'Yes' && row.FATHER_GUARDIAN_CNIC !== '') || (row.IS_FATHER_GAURDIAN_CNIC_AVAILABLE === 'No' && row.FATHER_GUARDIAN_CNIC === '');
          if (!yesValidGuardianCNIC) {
              rowValidationErrors.push(`If Parents/Guardian CNIC availability is "Yes", then "Father/Mother/Guardian CNIC" column must not be empty`);
          }

          console.log('yesValidGuardianCNIC:', yesValidGuardianCNIC);
      
          const isValidBFormLength = (row.STUDENT_BFORM === '' || (row.STUDENT_BFORM !== null && row.STUDENT_BFORM !== undefined && row.STUDENT_BFORM.length === 15));
          if (row.STUDENT_BFORM !== '' && !isValidBFormLength) {
              rowValidationErrors.push(`Student B-Form No. must be 15 digits, "-" not included`);
          }

          console.log('isValidBFormLength:', isValidBFormLength);
      
          const isValidGuardianCNICLength = row.FATHER_GUARDIAN_CNIC === '' || (row.FATHER_GUARDIAN_CNIC !== undefined && row.FATHER_GUARDIAN_CNIC !== null && row.FATHER_GUARDIAN_CNIC.length === 15);
          if (!isValidGuardianCNICLength) {
              rowValidationErrors.push(`Parents/Guardian CNIC No. must be 15 digits, "-" included`);
          }
          
          console.log('isValidGuardianCNICLength:', isValidGuardianCNICLength);
      
          const isValidStdBForm = !row.STUDENT_BFORM || /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/.test(row.STUDENT_BFORM);
          if (!isValidStdBForm) {
              rowValidationErrors.push(`Student B-Form No. is not valid or "-" is not included`);
          }
          console.log('isValidStdBForm:', isValidStdBForm);
      
          const isValidGuardianCNIC = !row.FATHER_GUARDIAN_CNIC || /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/.test(row.FATHER_GUARDIAN_CNIC);
          if (!isValidGuardianCNIC) {
              rowValidationErrors.push(`Parents/Guardian CNIC not valid or "-" not included`);
          }
          console.log('isValidGuardianCNIC:', isValidGuardianCNIC);
      
          const isValidStudentName = row.STUDENT_NAME !== '' && /^[A-Za-z\-\'\s]+$/.test(row.STUDENT_NAME);

          if (!isValidStudentName) {
            rowValidationErrors.push(`Student Name must not be empty and must contain only alphabetic characters, spaces, hyphens, and apostrophes`);
          }
            console.log('isValidGuardianCNIC:', isValidStudentName);

          const isvalid_FATHER_GUARDIAN_CONTACT_NO = !row.FATHER_GUARDIAN_CONTACT_NO || /^\d{11}$/.test(row.FATHER_GUARDIAN_CONTACT_NO);
          if (!isvalid_FATHER_GUARDIAN_CONTACT_NO) {
              rowValidationErrors.push(`If provided, FATHER_GUARDIAN_CONTACT_NO must be a 11-digit number`);
          }
          console.log('FATHER_GUARDIAN_CONTACT_NO:', isvalid_FATHER_GUARDIAN_CONTACT_NO);

          const isValidGuardianName = row.FATHER_GUARDIAN_NAME !== '' && /^[A-Za-z\-\'\s]+$/.test(row.FATHER_GUARDIAN_NAME);
          if (!isValidGuardianName) {
              rowValidationErrors.push(`Parents/Guardian Name must not be empty and should be in alphabetic format`);
          }
          console.log('isValidGuardianName:', isValidGuardianName);
      
          const isValidRow =
              semisCode &&
              semisCodeIsValid &&
              SchoolName &&
              SchoolDistrict &&
              SchoolTaluka &&
              SchoolUC &&
              SchoolLEVEL &&
              SchoolGENDER &&
              StudentSTUDENT_LANGUAGE &&
              StudentGUARDIAN_RELATION &&
              isValidateSTUDENT_WITH_DISABILITY &&
              StudentSTUDENT_RELIGION &&
              StudentDATE_OF_ENROLMENT &&
              StudentSECTION &&
              StudentDATE_OF_BIRTH &&
              isValidStudentGRNO &&
              isValidBForm &&
              yesValidGuardianCNIC &&
              isValidBFormLength &&
              isValidStdBForm &&
              isValidGuardianCNICLength &&
              isValidGuardianCNIC &&
              isValidStudentName &&
              isvalid_FATHER_GUARDIAN_CONTACT_NO&&
              isValidateGRADE&&
              isValidateSTUDENT_GENDER&&
              isValidGuardianName;
      
          // console.log('isValidRow:', isValidRow);
      
          if (!isValidRow) {
              validationErrors.push({
                  row: rowIndex + 1,
                  errors: rowValidationErrors,
              });
              return false; // Return false for invalid rows
          }
          return true; // Return true for valid rows
      });
      
      if (validationErrors.length > 0) {
        // console.log('Validation Errors:', validationErrors);
        setValidationErrors(validationErrors);
        setExcelData(null);
      } else {
        // console.log('Original Data Length:', originalData.length);
        setExcelData(originalData.slice(0, 10));
        postDataToServer(originalData);
      }

    }

  };

  // Assuming you have a function to render individual error messages
  const renderErrorMessages = (errors) => {
    return errors.map((error, index) => (
      <div key={index}>
        {error}
      </div>
    ));
  };

  return (
    <div>
      <NavbarMenu />
      <Container>
        <div className="wrapper" style={{ marginTop: "110px" }}>
          <Row>
          <Col>
              <div className="mb-3">
                {
                  validationErrors.map((errorObj, index) => (
                    errorObj.errors.length > 0 && (
                      <div key={index}>
                        <div className="rowheading">Validation errors in row {errorObj.row}:</div>
                        <div className="rowerror">{renderErrorMessages(errorObj.errors)}</div>
                      </div>
                    )
                  ))
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="mb-3 fst-italic">Import student bulk data through Excelsheet</h5>
              <Row className="border-1 m-3">
                  <Col>
                    <p className="fw-bold">Download Excel Format: <a className="btn btn-primary ml-2" href="./Student Data Collection format.xlsx">Download</a> </p>
                </Col>
                <Col>
                  <p className="fw-bold">Instruction Sheet (to fill data into Excel file): <a target="_blank" className="btn btn-info ml-2" href="./BULK-ENTRY-EXCEL-FILE-INSTRUCTIONS.pdf"> Download </a></p>
                </Col>
             </Row>
              
              {/* form */}
              <form className="form-group custom-form" onSubmit={handleFileSubmit}>
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <input type="file" className="form-control" required onChange={handleFile} />
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <button type="submit" className="btn btn-info btn-md">Import Data</button>
                  </div>

                </div>
                {typeError && (
                  <div className="alert alert-danger" role="alert">{typeError}</div>
                )}
              </form>
            </Col>
           
           
          </Row>

          {/* view data */}
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (

            <div className="viewer">
              {excelData ? (
                <div className="table-responsive">

                  <table className="table striped">
                    <thead>
                      <tr>
                        {Object.keys(excelData[0]).map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {excelData.map((individualExcelData, index) => (
                        <tr key={index}>
                          {Object.keys(individualExcelData).map((key) => (
                            <td key={key}>{individualExcelData[key]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No File is uploaded yet!</div>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );

}

export default UploadBulkData;