import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col, Spinner, Accordion } from 'react-bootstrap';
import NavbarMenu from './NavbarMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';

function UpdateStudent() {
  const { ID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [schoolData, setSchoolData] = useState({});
  const [error, setError] = useState(null);

  const [fields, setFields] = useState({
    IS_BFROM_AVAILABLE: false,
    IS_FATHER_GUARDIAN_CNIC_AVAILABLE: false,
    STUDENT_BFORM: '',
    FATHER_GUARDIAN_CNIC: '',
    
  });
  const [errors, setErrors] = useState({
    STUDENT_BFORM: '',
    FATHER_GUARDIAN_CNIC: '',
  });

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: name === 'IS_BFROM_AVAILABLE' || name === 'IS_FATHER_GUARDIAN_CNIC_AVAILABLE' ? checked : value,
    }));

    if (name === 'IS_BFROM_AVAILABLE' && !checked || name === 'IS_FATHER_GUARDIAN_CNIC_AVAILABLE' && !checked) {
      setFields(prevFields => ({
        ...prevFields,
        STUDENT_BFORM: '',
        FATHER_GUARDIAN_CNIC: '',
      }));
    }

  };


  useEffect(() => {

    const usersemisid = JSON.parse(localStorage.getItem('userin'));
    fetch(`http://stddataapi.rsu-sindh.gov.pk/api/schooldata/${usersemisid}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.schooldata) {
          setSchoolData(data.schooldata);
          setError(null);
          setFields({
            SEMIS_CODE: data.schooldata.SEMIS_CODE || '', 
            SCHOOL_NAME: data.schooldata.SCHOOL_NAME || '', 
            DISTRICT: data.schooldata.DISTRICT || '', 
            TALUKA: data.schooldata.TALUKA || '', 
            UNION_COUNCIL: data.schooldata.UC || '', 
            LEVEL: data.schooldata.LEVEL || '', 
            GENDER: data.schooldata.GENDER || '', 
          });
        } else {
          alert('Invalid Response or Check your properties');
          // Handle invalid response or missing schooldata property here
        }
      })
      .catch((error) => {
        // setSchoolData({});
        setError('An error occurred');
        alert('Something went wrong!');
      });
  


    fetch(`https://stddataapi.rsu-sindh.gov.pk/api/studentdata/${ID}`)
      .then((response) => {
        response.json().then((result) => {
          const isBFormAvailable = result.studentData.IS_BFROM_AVAILABLE === "Yes";
          const isGaurdianCNICAvailable = result.studentData.IS_FATHER_GAURDIAN_CNIC_AVAILABLE === "Yes";
          setFields({
            ...result.studentData,
            IS_BFROM_AVAILABLE: isBFormAvailable,
            IS_FATHER_GAURDIAN_CNIC_AVAILABLE: isGaurdianCNICAvailable,
          });
          // setFields({
          //   result.studentData,
          //  });
          setIsLoading(false);
        });
      });
  }, [ID]);



  const handleSubmit = (event) => {

    event.preventDefault();
    // if (validateForm()) {

    fetch(`https://stddataapi.rsu-sindh.gov.pk/api/updatestudentrecord/${ID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(fields)
    })
      .then(result => {
        result.json().then((resp) => {
          // console.log(resp);
          if (resp.status === 404) {
            const updateData = resp.fields;
            // console.log(updateData);
            alert('Record not found!');
          }
          else if (resp.status === 200) {
            // console.log(resp);
            setFields({}); // Clear the form fields
            alert('Student record updated');
          } else {
            alert('Error Found.');
          }
          setIsLoading(false);
        });
      })
      .catch(error => {
        // console.error('Error fetching data:', error);
        setIsLoading(false);
        alert('Something went wrong!');
      });
    // }
  }

  return (
    <div>
      <NavbarMenu />

      <Container style={{ marginTop: "120px" }}>

        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div>
            <h4 className='mt-3 mb-3'> <FontAwesomeIcon icon={faUserPlus} /> Update StudentRecord</h4>

            <Form className='mb-3' onSubmit={handleSubmit}>


              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{fields.SEMIS_CODE} - {fields.SCHOOL_NAME} </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="SEMIS_CODE" as={Col}>
                          <Form.Label>SEMIS Code</Form.Label>
                          {/* <Form.Control required={true}   type="number" name='SEMIS_CODE'  placeholder="SEMIS Code" onChange={(event) => { this.setState({ SEMIS_CODE: event.target.value }) }}/> */}
                          <Form.Control required={true} disabled type="number" name='SEMIS_CODE' placeholder="SEMIS Code" value={fields.SEMIS_CODE} onChange={handleChange} />
                          <div className="errorMsg">{errors.SEMIS_CODE}</div>
                        </Form.Group>
                      </Col>
                      <Col xs={8}>
                        <Form.Group className="mb-3" controlId="SCHOOL_NAME">
                          <Form.Label>School Name</Form.Label>
                          <Form.Control disabled type="text" name='SCHOOL_NAME' placeholder="GGPS Xyz" value={fields.SCHOOL_NAME} onChange={handleChange} />
                          <div className="errorMsg">{errors.SCHOOL_NAME}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="DISTRICT">
                          <Form.Label>District</Form.Label>
                          <Form.Control disabled type="text" name='DISTRICT' placeholder="Hyderabad" value={fields.DISTRICT} onChange={handleChange} />
                          <div className="errorMsg">{errors.DISTRICT}</div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="TALUKA">
                          <Form.Label>Taluka</Form.Label>
                          <Form.Control disabled type="text" name='TALUKA' placeholder="" value={fields.TALUKA} onChange={handleChange} />
                          <div className="errorMsg">{errors.TALUKA}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Form.Group className="mb-3" controlId="UNION_COUNCIL">
                          <Form.Label>UC</Form.Label>
                          <Form.Control disabled type="text" name='UNION_COUNCIL' placeholder="UC-II" value={fields.UNION_COUNCIL} onChange={handleChange} />
                          <div className="errorMsg">{errors.UNION_COUNCIL}</div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="uc">
                          <Form.Label>LEVEL</Form.Label>
                          <Form.Control readOnly type="text" name='LEVEL' placeholder="Primary" value={fields.LEVEL} onChange={handleChange} />
                          <div className="errorMsg">{errors.LEVEL}</div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="uc">
                          <Form.Label>GENDER</Form.Label>
                          <Form.Control disabled type="text" name='GENDER' placeholder="Mix" value={fields.GENDER} onChange={handleChange} />
                          <div className="errorMsg">{errors.GENDER}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Form.Group className="mb-3" controlId="STUDENT_NAME">
                <Form.Label>Student name</Form.Label>
                <Form.Control type="text" required={true} name='STUDENT_NAME' placeholder="Enter Student name" value={fields.STUDENT_NAME} onChange={handleChange} />
                <div className="errorMsg">{errors.STUDENT_NAME}</div>
              </Form.Group>
                <Row>
        <Col>
          <div id='IS_BFROM_AVAILABLE' className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="IS_BFROM_AVAILABLE"
              value={fields.IS_BFROM_AVAILABLE}
              checked={fields.IS_BFROM_AVAILABLE}
              name='IS_BFROM_AVAILABLE'
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="IS_BFROM_AVAILABLE">
              Is Student B-Form Available?
            </label>
          </div>
        </Col>
        {fields.IS_BFROM_AVAILABLE ? (
          <Col xs={9}>
            <Form.Group className="mb-3" controlId="STUDENT_BFORM">
              <Form.Label>Student B-Form </Form.Label>
              <InputMask
                className='form-control'
                id='STUDENT_BFORM'
                mask="99999-9999999-9"
                name='STUDENT_BFORM'
                placeholder="Enter Student B-Form"
                value={fields.STUDENT_BFORM}
                onChange={handleChange}
              />
              <div className="errorMsg">{errors.STUDENT_BFORM}</div>
            </Form.Group>
          </Col>
        ) : null}
      </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="STUDENT_GRNO">
                    <Form.Label>Student GR No.</Form.Label>
                    <Form.Control type="text" name='STUDENT_GRNO' placeholder="Enter Student GR No" value={fields.STUDENT_GRNO} onChange={handleChange} />
                    <div className="errorMsg">{errors.STUDENT_GRNO}</div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="DATE_OF_ENROLMENT">
                    <Form.Label>Date of Enrolment</Form.Label>
                    <Form.Control type="date" placeholder="MM/DD/YYYY" name='DATE_OF_ENROLMENT' value={fields.DATE_OF_ENROLMENT} onChange={handleChange} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="DATE_OF_BIRTH">
                    <Form.Label>Student Date of Birth</Form.Label>
                    <Form.Control type="date" name='DATE_OF_BIRTH' placeholder="MM/DD/YYYY" value={fields.DATE_OF_BIRTH} onChange={handleChange} />
                    <div className="errorMsg">{errors.DATE_OF_BIRTH}</div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="GRADE">
                    <Form.Label>GRADE</Form.Label>
                    {/* <Form.Control  type="number" placeholder="Student GRADE e.g. 1, 4" value={fields.GRADE} onChange={handleChange} /> */}
                    <Form.Control as="select" name='GRADE' value={fields.GRADE} onChange={handleChange}>
                      <option value="">Select Grade</option>
                      <option value="ECE">ECE</option>
                      <option value="Kachi">Kachi</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </Form.Control>
                    <div className="errorMsg">{errors.GRADE}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="SECTION">
                    <Form.Label>SECTION</Form.Label>
                    <Form.Control as="select" name='SECTION' value={fields.SECTION} onChange={handleChange}>
                      <option value="">Select SECTION</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                    </Form.Control>
                    {/* <Form.Control  type="text" placeholder="Student SECTION e.g. A, B" value={fields.SECTION} onChange={handleChange} /> */}
                    <div className="errorMsg">{errors.SECTION}</div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="STUDENT_GENDER">
                    <Form.Label>Student Gender</Form.Label>
                    <Form.Control as="select" name='STUDENT_GENDER' value={fields.STUDENT_GENDER} onChange={handleChange}>
                      <option value="">Select Student Gender</option>
                      <option value="Boy">Boy</option>
                      <option value="Girl">Girl</option>
                    </Form.Control>
                    <div className="errorMsg">{errors.STUDENT_GENDER}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="STUDENT_RELIGION">
                    <Form.Label>Student Religion</Form.Label>
                    <Form.Control as="select" name='STUDENT_RELIGION' value={fields.STUDENT_RELIGION} onChange={handleChange}>
                      <option value="">Select student Religion</option>
                      <option value="Islam">Islam</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Christian">Christian</option>
                      <option value="Qadiani/Ahmedi">Qadiani/Ahmedi</option>
                      <option value="Sikh">Sikh</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {fields.STUDENT_RELIGION === 'Other' && ( // Check if 'Other' is selected
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="OTHER_RELIGION">
                      <Form.Label>Specify Religion:</Form.Label>
                      <Form.Control
                        type="text"
                        name='OTHER_RELIGION'
                        placeholder=""
                        value={fields.OTHER_RELIGION}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">{errors.OTHER_RELIGION}</div>
                    </Form.Group>
                  </Col>
                )}
                <Col>
                  <Form.Group className="mb-3" controlId="STUDENT_LANGUAGE">
                    <Form.Label>Mother Tongue</Form.Label>
                    <Form.Control as="select" name='STUDENT_LANGUAGE' value={fields.STUDENT_LANGUAGE} onChange={handleChange}>
                      <option value="">Select student language</option>
                      <option value="Sindhi">Sindhi</option>
                      <option value="Urdu">Urdu</option>
                      <option value="Punjabi">Punjabi</option>
                      <option value="English">English</option>
                      <option value="Siraiki">Siraiki</option>
                      <option value="Pashto">Pashto</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {fields.STUDENT_LANGUAGE === 'Other' && ( // Check if 'Other' is selected
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="OTHER_LANGUAGE">
                      <Form.Label>Specify Student Language:</Form.Label>
                      <Form.Control
                        type="text"
                        name='OTHER_LANGUAGE'
                        placeholder=""
                        value={fields.OTHER_LANGUAGE}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">{errors.OTHER_LANGUAGE}</div>
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <Form.Group className="mb-3" controlId="PRESENT_ADDRESS">
                <Form.Label>Present Address</Form.Label>
                <Form.Control type="text" name='PRESENT_ADDRESS' placeholder="Enter Present Address" value={fields.PRESENT_ADDRESS} onChange={handleChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="FATHER_GUARDIAN_NAME">
                <Form.Label>Father/Mother/Guardian Name</Form.Label>
                <Form.Control type="text" name='FATHER_GUARDIAN_NAME' placeholder="Enter Father/Guardian Name" value={fields.FATHER_GUARDIAN_NAME} onChange={handleChange} />
                <div className="errorMsg">{errors.FATHER_GUARDIAN_NAME}</div>
              </Form.Group>
              <Row>
        <Col>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="IS_FATHER_GUARDIAN_CNIC_AVAILABLE"
              // value={fields.IS_FATHER_GUARDIAN_CNIC_AVAILABLE}
              checked={fields.IS_FATHER_GUARDIAN_CNIC_AVAILABLE}
              name='IS_FATHER_GUARDIAN_CNIC_AVAILABLE'
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="IS_FATHER_GUARDIAN_CNIC_AVAILABLE">
              Is Father/Mother/Guardian CNIC Available?
            </label>
          </div>
        </Col>
        {fields.IS_FATHER_GUARDIAN_CNIC_AVAILABLE ? (
          <Col xs={9}>
            <Form.Group className="mb-3" controlId="FATHER_GUARDIAN_CNIC">
              <Form.Label>Father/Mother/Guardian CNIC</Form.Label>
              <InputMask
                className='form-control'
                mask="99999-9999999-9"
                name='FATHER_GUARDIAN_CNIC'
                placeholder="Enter Father/Guardian CNIC"
                value={fields.FATHER_GUARDIAN_CNIC}
                onChange={handleChange}
              />
              <div className="errorMsg">{errors.FATHER_GUARDIAN_CNIC}</div>
            </Form.Group>
          </Col>
        ) : null}
      </Row>

              <Form.Group className="mb-3" controlId="FATHER_GUARDIAN_CONTACT_NO">
                <Form.Label>Father/Mother/Guardian Phone No.</Form.Label>
                {/* <Form.Control type="number" name='FATHER_GUARDIAN_CONTACT_NO' placeholder="Enter Father/Guardian Contact No." value={fields.FATHER_GUARDIAN_CONTACT_NO} onChange={handleChange} /> */}
                <InputMask className='form-control' mask="0399-9999999" name='FATHER_GUARDIAN_CONTACT_NO' placeholder="Enter Father/Guardian Contact No." value={fields.FATHER_GUARDIAN_CONTACT_NO} onChange={handleChange} />
                <div className="errorMsg">{errors.FATHER_GUARDIAN_CONTACT_NO}</div>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="GUARDIAN_RELATION">
                    <Form.Label>Guardian Relation</Form.Label>
                    <Form.Control as="select" name='GUARDIAN_RELATION' value={fields.GUARDIAN_RELATION} onChange={handleChange}>
                      <option value="">Select Parent/Gaurdian Relation</option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Uncle">Uncle</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                    <div className="errorMsg">{errors.GUARDIAN_RELATION}</div>
                  </Form.Group>
                </Col>
                {fields.GUARDIAN_RELATION === 'Other' && ( // Check if 'Other' is selected
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="OTHER_GUARDIAN_RELATION">
                      <Form.Label>Specify Gaurdian Relation:</Form.Label>
                      <Form.Control
                        type="text"
                        name='OTHER_GUARDIAN_RELATION'
                        placeholder=""
                        value={fields.OTHER_GUARDIAN_RELATION}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">{errors.OTHER_GUARDIAN_RELATION}</div>
                    </Form.Group>
                  </Col>
                )}
                <Col>
                  <Form.Group className="mb-3" controlId="STUDENT_WITH_DISABILITY">
                    <Form.Label>Is student Disable?</Form.Label>
                    <Form.Control as="select" name='STUDENT_WITH_DISABILITY' value={fields.STUDENT_WITH_DISABILITY} onChange={handleChange}>
                      <option value="">Select Option (Yes/No)</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Control>
                    <div className="errorMsg">{errors.STUDENT_WITH_DISABILITY}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="REMARKS">
                <Form.Label>Remarks</Form.Label>
                <Form.Control as="textarea" name='REMARKS' placeholder="Enter Remarks" value={fields.REMARKS} onChange={handleChange} />
                <div className="errorMsg">{errors.REMARKS}</div>
              </Form.Group>

              <Row>
                <Col>
                  <Button size='lg' className='d-block' variant="primary" type="submit">
                    Submit
                  </Button>
                  {/* <Button  onClick={() => { this.addstudent() }} className='d-block' variant="primary" type="submit">
            Submit
        </Button> */}
                </Col>

              </Row>
            </Form>
          </div>
        )}
      </Container>
    </div>
  );
}

export default UpdateStudent;
