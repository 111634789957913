import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Form, Pagination, Button  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck, faDownload  } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import NavbarMenu from './NavbarMenu';

function DistrictRemainingSchools() {
    const [enrolData, setEnrolData] = useState([]);
    const [filteredEnrolData, setFilteredEnrolData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [mode, setMode] = useState('loading');


    useEffect(() => {
        let semis_code = JSON.parse(localStorage.getItem('userin'));
        fetch(`https://stddataapi.rsu-sindh.gov.pk/api/districtremainingschools/${semis_code}`)
            .then((response) => response.json())
            .then((data) => {
                setEnrolData(data.districtremainingschools || []); 
                setFilteredEnrolData(data.districtremainingschools || []); 
                localStorage.setItem('districtremainingschools', JSON.stringify(data.districtremainingschools));
                setMode('online');
            })
            .catch(() => {
                setMode('offline');
                let districtremainingschools = localStorage.getItem('districtremainingschools');
                setEnrolData(JSON.parse(districtremainingschools) || []); 
                setFilteredEnrolData(JSON.parse(districtremainingschools) || []); 
            });
    }, [itemsPerPage]);


    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    
        // Check if studentData is not null or undefined
        if (enrolData) {
            const filteredData = enrolData.filter((tenrol) => {
                const schoolName = (tenrol.SCHOOL_NAME || '').toLowerCase();
                const semisCode = (tenrol.SEMIS_CODE || '').toString().toLowerCase(); // Assuming SEMIS_CODE is a number
    
                return schoolName.includes(query) || semisCode.includes(query);
            });
    
            setFilteredEnrolData(filteredData);
            setCurrentPage(1);
        }
    };

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredEnrolData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');
        XLSX.writeFile(workbook, 'students.xlsx');
    };

    const totalPages = Math.ceil(filteredEnrolData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedEnrolData = filteredEnrolData.slice(startIndex, endIndex);

    const renderPaginationItems = () => {
        const MAX_VISIBLE_PAGES = 3;
        const items = [];
        for (let i = 1; i <= Math.min(totalPages, 10); i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

    // Show Ellipsis if there are more than 16 total pages
    if (totalPages > 10) {
        items.push(<Pagination.Ellipsis key="ellipsis-after-16" />);
    }

    for (let i = Math.max(totalPages - MAX_VISIBLE_PAGES, 11); i <= totalPages; i++) {
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => setCurrentPage(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    return items;
};

    var row_num = 1;

    return (
        <div>
            <NavbarMenu />
            <Container>

                <div>
                    {mode === 'offline' ? (
                        <div className="alert alert-warning" role="alert">
                            You are offline or there is an issue with the connection!
                        </div>
                    ) : null}
                </div>

                <div className="row" style={{ marginTop: "120px" }}>
                    <div className="col-md-5 col-sm-5">
                        <h4 className="mt-3 mb-2 text-danger">
                            <FontAwesomeIcon icon={faListCheck} /> Remaining Schools
                        </h4>
                    </div>
                   
                    <div className="col-md-4 col-sm-4">
                        <Form.Group className="mb-3" controlId="uc">
                            <Form.Control
                                type="text"
                                placeholder="Search by name..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 col-sm-3 pull-right">
                        <Button variant="primary" className="mb-2" onClick={handleDownloadExcel}>
                            Export in Excel <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </div>
                </div>

                {mode === 'loading' ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        
                    </div>
                   
                ) : (
                    
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th className="text-center">SNO.</th>
                                <th className="text-center">SEMIS CODE</th>
                                <th>School Name</th>
                                <th className="text-center">Total Enrollment as per ASC 2022-23</th>
                            </tr>
                        </thead>
                        <tbody>
                        {paginatedEnrolData.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="text-center">Data not entered yet</td>
                            </tr>
                        ) : (
                            paginatedEnrolData.map((totalenrol, index) => (
                                <tr key={totalenrol.SEMIS_CODE}>
                                    <td className="text-center">{row_num++}</td>
                                    <td className="text-center">{totalenrol.SEMIS_CODE}</td>
                                    <td>{totalenrol.SCHOOL_NAME}</td>
                                    <td className="text-center">{totalenrol.previous_year_enrollment}</td>
                                </tr>
                            ))
                            
                        )}
                        
                        </tbody>
                       
                    </Table>
                    
                )}
         
                <div className="d-flex justify-content-center">
                    <Pagination>
                        {currentPage > 1 && <Pagination.First onClick={() => setCurrentPage(1)} />}
                        {currentPage > 1 && <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />}
                        {/* Render pagination items */}
                        {renderPaginationItems()}

                        {/* Add First and Last Page Navigation if needed */}
                        {currentPage < totalPages && <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />}
                        {currentPage < totalPages && <Pagination.Last onClick={() => setCurrentPage(totalPages)} />}
                    </Pagination>
                </div>
                  
          
            </Container>
        </div>
    );
}

export default DistrictRemainingSchools;
