import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Row, Col, Button } from 'react-bootstrap';
import NavbarMenu from './NavbarMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchoolFlag, faSchoolCircleCheck, faChildDress, faUser, faGraduationCap, faPerson, faAddressCard, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import StudentList from './StudentList';
import { Link } from "react-router-dom";
import SchoolWiseEnrolCount from './EnrolmentCount';
import DistrictWiseList from './DistrictWiseList';
import DistrictWiseStatus from './DistrictWiseStatus';
import DistrictWiseStudentList from './DistrictWiseStudentList';

// class Home extends Component {
function Home({ semiscode }) {

    // const [studentData, setStudentData] = useState([]);
    const [SpecificschoolData, setSpecificschoolData] = useState([]);
    const [mode, setMode] = useState('loading');
    const [countStudentData, setcountStudentData] = useState(SpecificschoolData);

    useEffect(() => {

        let semiscode = JSON.parse(localStorage.getItem('userin'));
        fetch(`https://stddataapi.rsu-sindh.gov.pk/api/schooldatacount/${semiscode}`)
            .then((response) => response.json())
            .then((data) => {
                // let userin = localStorage.getItem('userin');
                const countStudentData = data
                setSpecificschoolData(countStudentData);
                // console.log(countStudentData);
                localStorage.setItem('totalstudententry', JSON.stringify(countStudentData));
                setMode('online');
            })
            .catch((error) => {
                setMode('offline');
                let collection = localStorage.getItem('totalstudententry');
                setSpecificschoolData(JSON.parse(collection));
            });

    }, [semiscode]);



    return (
        <div>
            <NavbarMenu />
            <Container>
                <div>
                    {
                        mode === "offline" ?
                            <div className="alert alert-warning" role="alert">
                                You are offline mode or some issue with connection!
                            </div> :
                            null
                    }
                </div>
                {mode === 'loading' ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <div>
                        <main className="py-6 bg-surface-secondary" style={{ marginTop: "75px" }}>
                            <div className="container-fluid">

                                {SpecificschoolData && (
                                    <div>
                                        <div className="row ml-0 mr-0 mb-2">
                                            <div className="col-sm-12 col-md-12 p-2 border-1 rounded d-flex">
                                                {SpecificschoolData.schoolname_record.length > 0 ? (
                                                    SpecificschoolData.schoolname_record.map((school, index) => (
                                                        <h4 key={school.SEMIS_CODE}>{school.SEMIS_CODE} - {school.SCHOOL_NAME}</h4>
                                                    ))
                                                ) : (
                                                    <h4> RSU SE&LD </h4>
                                                )}
                                                {SpecificschoolData.usertype_record.SCHOOL_NAME == "LSU" && (
                                                 <h4 className='ml-2'>{SpecificschoolData.districtuser_info_record.SCHOOL_NAME} - {SpecificschoolData.districtuser_info_record.SEMIS_CODE}</h4>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row g-6 mb-6">
                                            {SpecificschoolData.usertype == 123456789 && (
                                                <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                    <div className="card shadow border-0">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">Schools under <small>C-3</small></span>
                                                                    <span className="h3 font-bold mb-0">{SpecificschoolData.school_record}</span>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="icon icon-shape bg-tertiary text-success text-lg rounded-circle">
                                                                        <FontAwesomeIcon icon={faSchoolFlag} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mb-0 text-sm">
                                                                <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                    <FontAwesomeIcon icon={faSchoolFlag} />
                                                                </span>
                                                                <span className="text-nowrap text-xs text-muted">SELECT Component-3</span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {SpecificschoolData.usertype == 123456789 && (
                                                <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                    <div className="card shadow border-0">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Schools Started Entries</span>
                                                                    <span className="h3 font-bold mb-0">{SpecificschoolData.school_record_collected}</span>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="icon icon-shape bg-tertiary text-info text-lg rounded-circle">
                                                                        <FontAwesomeIcon icon={faSchoolFlag} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mb-0 text-sm">
                                                                <span className="badge badge-pill bg-soft-warning text-info me-2">
                                                                    <FontAwesomeIcon icon={faSchoolFlag} /> {(SpecificschoolData.school_record_collected * 100 / SpecificschoolData.school_record).toFixed(2) + '%'}
                                                                </span>
                                                                <span className="text-nowrap text-xs text-muted">Schools Started Entries in %</span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                        <div className="row g-6 mb-6">
                                            {SpecificschoolData.usertype_record.SCHOOL_NAME == "LSU" && (
                                                <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                    <div className="card shadow border-0">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">Schools under <small>C-3</small></span>
                                                                    <span className="h3 font-bold mb-0">{SpecificschoolData.school_district_record}</span>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="icon icon-shape bg-tertiary text-success text-lg rounded-circle">
                                                                        <FontAwesomeIcon icon={faSchoolFlag} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mb-0 text-sm">
                                                                <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                    <FontAwesomeIcon icon={faSchoolFlag} />
                                                                </span>
                                                                <span className="text-nowrap text-xs text-muted">SELECT Component-3</span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {SpecificschoolData.usertype_record.SCHOOL_NAME == "LSU" && (
                                                <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                                    <div className="card shadow border-0">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Schools Started Entries</span>
                                                                    <span className="h3 font-bold mb-0 text-success">{SpecificschoolData.district_school_total_collected}</span>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="icon icon-shape bg-tertiary text-info text-lg rounded-circle">
                                                                        <FontAwesomeIcon icon={faSchoolFlag} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mb-0 text-sm">
                                                                <span className="badge badge-pill bg-soft-warning text-info me-2">
                                                                    <FontAwesomeIcon icon={faSchoolFlag} /> {(SpecificschoolData.district_school_total_collected * 100 / SpecificschoolData.school_district_record).toFixed(2) + '%'}
                                                                </span>
                                                                <span className="text-nowrap text-xs text-muted">Schools Started Entries in %</span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {SpecificschoolData.usertype_record.SCHOOL_NAME == "LSU" && (
                                                <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                    <div className="card shadow border-0">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                
                                                                <div className="col">
                                                                    <Link to="/DistrictRemainingSchools" className='linkstyles'>
                                                                    <span className="h6 font-semibold text-muted text-sm d-block mb-2 text-danger">Remaining Total Schools</span>
                                                                    <span className="h3 font-bold mb-0 text-danger">{parseInt(SpecificschoolData.school_district_record, 10) - parseInt(SpecificschoolData.district_school_total_collected, 10)}</span>
                                                                    </Link>
                                                                </div>
                                                               
                                                                
                                                                <div className="col-auto">
                                                                <Link to="/DistrictRemainingSchools" className='linkstyles'>
                                                                    <div className="icon icon-shape bg-tertiary text-danger text-lg rounded-circle">
                                                                        <FontAwesomeIcon icon={faSchoolFlag} />
                                                                    </div>
                                                                    </Link>
                                                                </div>
                                                           
                                                            </div>
                                                            <div className="mt-2 mb-0 text-sm">
                                                                <span className="badge badge-pill bg-soft-warning text-danger me-2">
                                                                    <FontAwesomeIcon icon={faSchoolFlag}/>
                                                                </span>
                                                                <span className="text-nowrap text-xs text-muted"></span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            </div>
                                            {SpecificschoolData.usertype_record.SCHOOL_NAME == "LSU" && (
                                                <div className="row g-6 mb-6">
                                                    <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                        <div className="card shadow border-0">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Enrolment <small>ASC 2022-23</small></span>
                                                                        <span className="h3 font-bold mb-0">{SpecificschoolData.total_enrolled_district}</span>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="icon icon-shape bg-tertiary text-success text-lg rounded-circle">
                                                                            <FontAwesomeIcon icon={faGraduationCap} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mb-0 text-sm">
                                                                    <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                        <FontAwesomeIcon icon={faGraduationCap} />
                                                                    </span>
                                                                    <span className="text-nowrap text-xs text-muted">Based on Annual Census 2022-23</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                        <div className="card shadow border-0">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Enrolment Submitted</span>
                                                                        <span className="h3 font-bold mb-0">{SpecificschoolData.district_submitted_std_all}</span>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="icon icon-shape text-warning text-lg rounded-circle">
                                                                            <FontAwesomeIcon icon={faSchoolCircleCheck} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mb-0 text-sm">
                                                                    <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                        <FontAwesomeIcon icon={faSchoolCircleCheck} /> {(SpecificschoolData.district_submitted_std_all * 100 / SpecificschoolData.total_enrolled_district).toFixed(2) + '%'}
                                                                    </span>
                                                                    <span className="text-nowrap text-xs text-muted">Total in %</span>
                                                                </div>




                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                        <div className="card shadow border-0">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Girls Students</span>
                                                                        <span className="h3 font-bold mb-0">{SpecificschoolData.district_submitted_stdGirls_all}</span>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="icon icon-shape bg-info text-white text-lg">
                                                                            <FontAwesomeIcon icon={faChildDress} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mb-0 text-sm">
                                                                    <span className="badge badge-pill bg-soft-danger text-danger me-2">
                                                                        <FontAwesomeIcon icon={faChildDress} /> {(SpecificschoolData.district_submitted_stdGirls_all * 100 / SpecificschoolData.district_submitted_std_all).toFixed(2) + '%'}
                                                                    </span>
                                                                    <span className="text-nowrap text-xs text-muted">Girls Student Entries</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-md-3 col-sm-6 col-12s">
                                                        <div className="card shadow border-0">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Boys Students</span>
                                                                        <span className="h3 font-bold mb-0">{SpecificschoolData.district_submitted_stdBoys_all}</span>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="icon icon-shape bg-warning text-white text-lg">
                                                                            <FontAwesomeIcon icon={faPerson} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mb-0 text-sm">
                                                                    <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                        <FontAwesomeIcon icon={faPerson} />{(SpecificschoolData.district_submitted_stdBoys_all * 100 / SpecificschoolData.district_submitted_std_all).toFixed(2) + '%'}
                                                                    </span>
                                                                    <span className="text-nowrap text-xs text-muted">Boys student entries</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {SpecificschoolData.usertype_record.SCHOOL_NAME == "LSU" && (
                                                <DistrictWiseList/>
                                         )}
                                          {SpecificschoolData.usertype_record.SCHOOL_NAME == "LSU" && (
                                                <DistrictWiseStudentList/>
                                         )}

                                        {SpecificschoolData.usertype_record.SCHOOL_NAME != "LSU" && (
                                        <div className="row g-6 mb-6">

                                            <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                <div className="card shadow border-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Enrolment <small>ASC 2022-23</small></span>
                                                                <span className="h3 font-bold mb-0">{SpecificschoolData.schoolenrolment_semisrecord}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="icon icon-shape bg-tertiary text-success text-lg rounded-circle">
                                                                    <FontAwesomeIcon icon={faGraduationCap} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 mb-0 text-sm">
                                                            <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                <FontAwesomeIcon icon={faGraduationCap} />
                                                            </span>
                                                            <span className="text-nowrap text-xs text-muted">Based on Annual Census 2022-23</span>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                <div className="card shadow border-0">
                                               
                                                    <div className="card-body">
                                                    <Link to="/studentlist" className='linkstyles'>
                                                        <div className="row">
                                                       
                                                            <div className="col">
                                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Enrolment Submitted</span>
                                                                <span className="h3 font-bold mb-0">{SpecificschoolData.countAll}</span>
                                                            </div>
                                                           
                                                            <div className="col-auto">
                                                                <div className="icon icon-shape text-warning text-lg rounded-circle">
                                                                    <FontAwesomeIcon icon={faSchoolCircleCheck} />
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                        <div className="mt-2 mb-0 text-sm">
                                                            <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                <FontAwesomeIcon icon={faSchoolCircleCheck} /> {(SpecificschoolData.countAll * 100 / SpecificschoolData.schoolenrolment_semisrecord).toFixed(2) + '%'}
                                                            </span>
                                                            <span className="text-nowrap text-xs text-muted">Total in %</span>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3 col-sm-6 col-12">
                                                <div className="card shadow border-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Girls Students</span>
                                                                <span className="h3 font-bold mb-0">{SpecificschoolData.countGirls}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="icon icon-shape bg-info text-white text-lg">
                                                                    <FontAwesomeIcon icon={faChildDress} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 mb-0 text-sm">
                                                            <span className="badge badge-pill bg-soft-danger text-danger me-2">
                                                                <FontAwesomeIcon icon={faChildDress} /> {(SpecificschoolData.countGirls * 100 / SpecificschoolData.countAll).toFixed(2) + '%'}
                                                            </span>
                                                            <span className="text-nowrap text-xs text-muted">Girls Student Entries</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-3 col-sm-6 col-12s">
                                                <div className="card shadow border-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Boys Students</span>
                                                                <span className="h3 font-bold mb-0">{SpecificschoolData.countBoys}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="icon icon-shape bg-warning text-white text-lg">
                                                                    <FontAwesomeIcon icon={faPerson} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 mb-0 text-sm">
                                                            <span className="badge badge-pill bg-soft-success text-success me-2">
                                                                <FontAwesomeIcon icon={faPerson} />{(SpecificschoolData.countBoys * 100 / SpecificschoolData.countAll).toFixed(2) + '%'}
                                                            </span>
                                                            <span className="text-nowrap text-xs text-muted">Boys student entries</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        )}

                                        
                                        

                                    </div>

                                )}

                            </div>
                        </main>
                        {SpecificschoolData.usertype_record.SCHOOL_NAME != "LSU" && (
                            <Row className='mb-2'>
                                <Col>
                                    <Link to="/addstudent" className=''>  <Button variant="primary" size="lg"><FontAwesomeIcon icon={faAddressCard} /> Add a Student </Button> </Link>
                                </Col>
                                <Col>
                                    <Link to="/uploadbulkdata" className=''> <Button variant="success" size="lg"><FontAwesomeIcon icon={faFileExcel} /> Import Excel File </Button> </Link>
                                </Col>
                            </Row>
                            
                            )}

                           {/* {SpecificschoolData.usertype_record.SCHOOL_NAME !== "LSU" && SpecificschoolData.usertype_record.SCHOOL_NAME !== 123456789 ? (
                            <StudentList />
                            ) : null} */}

                            <div>
                            {SpecificschoolData.usertype == 123456789 && (
                                <DistrictWiseStatus />
                            )}
                        </div>

                        <div>
                            {SpecificschoolData.usertype == 123456789 && (
                                <SchoolWiseEnrolCount />
                            )}
                        </div>

                    
                    </div>
                )}
            </Container>
        </div>
    );
}


export default Home;