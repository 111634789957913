import React, { Component } from 'react';
import { Button, Container, Image, Nav, Navbar, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faHouseUser, faUser, faIdBadge } from '@fortawesome/free-solid-svg-icons';

class NavbarMenu extends Component {
    render() {
        // const adminuser = localStorage.getItem('userin');
        let adminuser = JSON.parse(localStorage.getItem('userin'));
        return (
            <Navbar expand="lg" className="bg-body-tertiary" fixed="top">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <Image className='text-center' src="/apple-touch-icon.png" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto my-2 my-lg-0">
                            <Nav.Link href="/" className='fw-bold'> <Button variant="outline-success"> <FontAwesomeIcon icon={faHouseUser} /> Home  </Button> </Nav.Link >
                            
                            {adminuser == '123456789' && (

                                <Nav.Link href="/userlist" className='fw-bold'>
                                    <Button variant="outline-info">
                                        <FontAwesomeIcon icon={faUser} /> Users
                                    </Button>
                                </Nav.Link>

                            )}

                            {/* <Nav.Link href="/addstudent"> <FontAwesomeIcon icon={faDatabase} /> Add Student </Nav.Link> */}
                            {/* <Nav.Link href="/studentlist"> <FontAwesomeIcon icon={faAddressCard} /> Student List </Nav.Link> */}
                            {/* <Nav.Link href="/uploadbulkdata"> <FontAwesomeIcon icon={faFileExcel} /> Upload Bulk Data </Nav.Link> */}
                        </Nav>
                        <div className="d-flex justify-content-between">
                            <Stack direction="horizontal" gap={3}>
                                <Nav.Link href="/profile"><Button variant="outline-info"> <FontAwesomeIcon icon={faIdBadge} /> Profile </Button> </Nav.Link>
                                {
                                    localStorage.getItem('userin') ?

                                        <Nav.Link className="" href="/logout"> <Button variant="outline-danger"> <FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout </Button> </Nav.Link>
                                        :
                                        <Nav.Link className="" href="/login"> <Button variant="outline-danger"> <FontAwesomeIcon icon={faArrowRightFromBracket} /> Login </Button> </Nav.Link>
                                }
                            </Stack>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        );
    }
}

export default NavbarMenu;