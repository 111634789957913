import React, { Component } from 'react';
import { Container, Form, Button, Row, Col, Spinner, Accordion } from 'react-bootstrap';
import moment from 'moment';
import NavbarMenu from './NavbarMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
// import StyledAlert from './StyledAlert';

class AddStudent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        IS_BFROM_AVAILABLE: false,
        IS_FATHER_GAURDIAN_CNIC_AVAILABLE: false,
      },
      errors: {},
      isLoading: false, // Add loading state
      schoolData: {},
      error: null,
      // alertMessage: '',

    }
    this.handleChange = this.handleChange.bind(this);
    this.addstudent = this.addstudent.bind(this);
  }



  handleChange = (event) => {

    const { name, value, checked } = event.target;
    let fields = { ...this.state.fields };
    fields[event.target.name] = event.target.value;


    if (name === 'IS_BFROM_AVAILABLE') {
      fields[name] = checked;
      // If the switch is turned off, clear STUDENT_BFORM field
      if (!checked) {
        fields['STUDENT_BFORM'] = '';
      }
    } else if (name === 'IS_FATHER_GAURDIAN_CNIC_AVAILABLE') {
      fields[name] = checked;
      // If the switch is turned off, clear both FATHER_GUARDIAN_CNIC and STUDENT_BFORM fields
      if (!checked) {
        fields['FATHER_GUARDIAN_CNIC'] = '';
        fields['STUDENT_BFORM'] = '';
      }
    } else {
      fields[name] = value;
    }

    this.setState({ fields });
  };
  
  componentDidMount() {
    // Add an event listener to detect when the user goes online
    window.addEventListener('online', this.handleOnline);

    const usersemisid = JSON.parse(localStorage.getItem('userin'));
    fetch(`https://stddataapi.rsu-sindh.gov.pk/api/schooldata/${usersemisid}`)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('SchoolData', JSON.stringify(data.schooldata));
        this.setState({
          schoolData: data.schooldata,
          error: null,
          fields: {
            SEMIS_CODE: data.schoolData.SEMIS_CODE,
            SCHOOL_NAME: data.schoolData.SCHOOL_NAME,
            DISTRICT: data.schoolData.DISTRICT,
            TALUKA: data.schoolData.TALUKA,
            UNION_COUNCIL: data.schoolData.UC,
            LEVEL: data.schoolData.LEVEL,
            GENDER: data.schoolData.GENDER,
            // Add other fields as needed
          },
        });
        
      })
      .catch((error) => {
        let userlist = localStorage.getItem('SchoolData');
        console.log(userlist);
        console.log(userlist.schooldata);
        this.setState({
          schoolData: userlist.schooldata,
          fields: {
            SEMIS_CODE: userlist.schoolData.SEMIS_CODE,
            SCHOOL_NAME: userlist.schoolData.SCHOOL_NAME,
            DISTRICT: userlist.schoolData.DISTRICT,
            TALUKA: userlist.schoolData.TALUKA,
            UNION_COUNCIL: userlist.schoolData.UC,
            LEVEL: userlist.schoolData.LEVEL,
            GENDER: userlist.schoolData.GENDER,
            // Add other fields as needed
          },
          error: 'An error occurred',
        });
        alert('Something went wrong!');
      });

  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window.removeEventListener('online', this.handleOnline);
    // window.addEventListener('online', this.specificSchool);
  }

  handleOnline = () => {
    // When the user is online, check for and upload any stored data
    const storedData = JSON.parse(localStorage.getItem('students')) || [];

    if (storedData.length > 0) {
      // Iterate through stored data and send it to the server
      storedData.forEach(studentData => {
        fetch("https://stddataapi.rsu-sindh.gov.pk/api/addstudent", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(studentData)
        })
          .then(result => {
            if (result.status === 200) {
              // Data uploaded successfully, remove it from localStorage
              const updatedData = storedData.filter(item => item !== studentData);
              localStorage.setItem('students', JSON.stringify(updatedData));
            }
          })
          .catch(error => {
            alert('Something went wrong!');
          });
      });
    }
  };

  addstudent = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      this.setState({ isLoading: true });

      fetch("https://stddataapi.rsu-sindh.gov.pk/api/addstudent", {
        method: 'POST',
        mode: "cors",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.state.fields)

      })
        .then(result => {
          result.json().then((resp) => {
            // console.log('response', resp);
            if (resp.status === 400) {
              alert('Inserting duplicate Student GR NO. or B-Form for the same School!');
              // this.setState({ alertMessage: 'Inserting duplicate Student GR NO. or B-Form for the same School!' });
            }
            else if (resp.status === 200) {

              // this.setState({ fields: {} });
              alert('Student inserted successfully!')
              // this.setState({ alertMessage: 'Student inserted successfully!' });
            } else {
              // Data failed to upload, store it in localStorage
              const storedData = JSON.parse(localStorage.getItem('students')) || [];
              storedData.push(this.state.fields);
              localStorage.setItem('students', JSON.stringify(storedData));
              alert('Data stored offline. It will be uploaded when online!')
              // this.setState({ alertMessage: 'Data stored offline. It will be uploaded when online!' });
            }

            this.setState({ isLoading: false });
          });
        })
        .catch(error => {
          // console.error('Error fetching data:', error);
          this.setState({ isLoading: false });
          alert('Something went wrong!')
          // this.setState({ alertMessage: 'Something went wrong!' });
        });
    }
  }


  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["SEMIS_CODE"]) {
      formIsValid = false;
      errors["SEMIS_CODE"] = "*Enter SEMIS_CODE Name!";
      alert('Please wait for the school data to be retrieved!')
    }

    if (!fields["STUDENT_NAME"]) {
      formIsValid = false;
      errors["STUDENT_NAME"] = "*Enter Student Name!";
    }

    if (typeof fields["STUDENT_NAME"] !== "undefined") {
      if (!fields["STUDENT_NAME"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["STUDENT_NAME"] = "*Student Name must be alphabet characters only!";
      }
    }

    if (!fields["STUDENT_GRNO"]) {
      formIsValid = false;
      errors["STUDENT_GRNO"] = "*Enter Student GR No. is Mandatory!";
    }

    if (!fields["STUDENT_RELIGION"]) {
      formIsValid = false;
      errors["STUDENT_RELIGION"] = "*Enter Student religion!";
    }

    if (!fields["STUDENT_LANGUAGE"]) {
      formIsValid = false;
      errors["STUDENT_LANGUAGE"] = "*Enter Student Mother Tongue!";
    }

    if (!fields["DATE_OF_BIRTH"]) {
      formIsValid = false;
      errors["DATE_OF_BIRTH"] = "*Enter Student DATE OF BIRTH!";
    }

    if (!fields["DATE_OF_ENROLMENT"]) {
      formIsValid = false;
      errors["DATE_OF_ENROLMENT"] = "*Enter Student DATE of Enrollment/Admission!";
    }

    if (!fields["GRADE"]) {
      formIsValid = false;
      errors["GRADE"] = "*Enter Student GRADE!";
    }

    if (!fields["SECTION"]) {
      formIsValid = false;
      errors["SECTION"] = "*Enter Student SECTION!";
    }
    if (!fields["STUDENT_GENDER"]) {
      formIsValid = false;
      errors["STUDENT_GENDER"] = "*Select Student STUDENT GENDER!";
    }

    if (!fields["FATHER_GUARDIAN_NAME"]) {
      formIsValid = false;
      errors["FATHER_GUARDIAN_NAME"] = "*Enter Student Father/Mother/Guardian Name!";
    }

    if (typeof fields["STUDENT_GRNO"] !== 'undefined' && fields["STUDENT_GRNO"] !== "") {
      // Regular expression to match one or more numeric digits
      const grNoPattern = /^[0-9]+$/;

      if (!grNoPattern.test(fields["STUDENT_GRNO"])) {
        formIsValid = false;
        errors["STUDENT_GRNO"] = "*Enter a valid Student GR No!";
      }
    }


    if (typeof fields["FATHER_GUARDIAN_CNIC"] !== "undefined" && fields["FATHER_GUARDIAN_CNIC"] !== "") {
      // Allow dashes in the CNIC number (matches 15 digits with optional dashes)
      if (!fields["FATHER_GUARDIAN_CNIC"].match(/^(\d{5}-\d{7}-\d{1}|\d{15})$/)) {
          formIsValid = false;
          errors["FATHER_GUARDIAN_CNIC"] = "*Enter valid Father/Guardian CNIC!";
      }
  }
  
  
  if (typeof fields["FATHER_GUARDIAN_CONTACT_NO"] !== "undefined" && fields["FATHER_GUARDIAN_CONTACT_NO"] !== "") {
    // Allow dashes in the phone number (matches 12 digits with optional dashes)
    if (!fields["FATHER_GUARDIAN_CONTACT_NO"].match(/^(\d{4}-\d{7}|\d{12})$/)) {
        formIsValid = false;
        errors["FATHER_GUARDIAN_CONTACT_NO"] = "*Enter valid Father/Guardian Phone no!";
    }
}

    
    if (typeof fields["STUDENT_BFORM"] !== "undefined" && fields["STUDENT_BFORM"] !== "") {
      // Allow dashes in the CNIC number (matches 15 digits with optional dashes)
      if (!fields["STUDENT_BFORM"].match(/^(\d{5}-\d{7}-\d{1}|\d{15})$/)) {
          formIsValid = false;
          errors["STUDENT_BFORM"] = "*Enter valid Student B-Form!";
      }
  }

  //   if (typeof fields["STUDENT_GENDER"] !== 'undefined' && fields["STUDENT_GENDER"] !== "") {
  //     // Extract the last digit of the B-Form
  //     const lastDigitBForm = parseInt(fields["STUDENT_BFORM"].slice(-1));
  //     // Check if last digit is odd for BOY and even for GIRL
  //     const isLastDigitOdd = lastDigitBForm % 2 !== 0;
  //     const isGenderValid = (isLastDigitOdd && fields["STUDENT_GENDER"] === 'Boy') || (!isLastDigitOdd && fields["STUDENT_GENDER"] === 'Girl');
  //     if (!isGenderValid) {
  //         formIsValid = false;
  //         errors["STUDENT_GENDER"] = "*Student Gender does not match B-Form last digit!";
  //     }
  // }
    

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  render() {
    const { schoolData, error } = this.state;
    const { alertMessage } = this.state;
    // const userid = JSON.parse(localStorage.getItem('userin'));
    // this.state.fields.SEMIS_CODE = userid;
    
    return (
      <div>
        <NavbarMenu />
        <Container style={{ marginTop: "120px" }}>

          {this.state.isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div>

              <h4 className='mt-3 mb-3'> <FontAwesomeIcon icon={faUserPlus} /> Student Entry form</h4>
              <Form className='mb-3' onSubmit={this.addstudent}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{this.state.fields.SEMIS_CODE} - {this.state.fields.SCHOOL_NAME} </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="SEMIS_CODE" as={Col} >
                            <Form.Label>SEMIS Code</Form.Label>
                            {/* <Form.Control required={true}   type="number" name='SEMIS_CODE'  placeholder="SEMIS Code" onChange={(event) => { this.setState({ SEMIS_CODE: event.target.value }) }}/> */}
                            <Form.Control required={true} disabled type="number" name='SEMIS_CODE' placeholder="SEMIS Code" value={this.state.fields.SEMIS_CODE} onChange={this.handleChange} />
                            <div className="errorMsg">{this.state.errors.SEMIS_CODE}</div>
                          </Form.Group>
                        </Col>
                        <Col xs={8}>
                          <Form.Group className="mb-3" controlId="SCHOOL_NAME">
                            <Form.Label>School Name</Form.Label>{this.state.fields.SEMIS_CODE}
                            <Form.Control disabled type="text" name='SCHOOL_NAME' placeholder="GGPS Xyz" value={this.state.fields.SCHOOL_NAME} onChange={this.userinfoChange} />
                            <div className="errorMsg">{this.state.errors.SCHOOL_NAME}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="DISTRICT">
                            <Form.Label>District</Form.Label>
                            <Form.Control disabled type="text" name='DISTRICT' placeholder="Hyderabad" value={this.state.fields.DISTRICT} onChange={this.userinfoChange} />
                            <div className="errorMsg">{this.state.errors.DISTRICT}</div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="TALUKA">
                            <Form.Label>Taluka</Form.Label>
                            <Form.Control disabled type="text" name='TALUKA' placeholder="" value={this.state.fields.TALUKA} onChange={this.userinfoChange} />
                            <div className="errorMsg">{this.state.errors.TALUKA}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Group className="mb-3" controlId="UNION_COUNCIL">
                            <Form.Label>UC</Form.Label>
                            <Form.Control disabled type="text" name='UNION_COUNCIL' placeholder="UC-II" value={this.state.fields.UNION_COUNCIL} onChange={this.userinfoChange} />
                            <div className="errorMsg">{this.state.errors.UNION_COUNCIL}</div>
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group className="mb-3" controlId="uc">
                            <Form.Label>LEVEL</Form.Label>
                            <Form.Control disabled type="text" name='LEVEL' placeholder="Primary" value={this.state.fields.LEVEL} onChange={this.userinfoChange} />
                            <div className="errorMsg">{this.state.errors.LEVEL}</div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="uc">
                            <Form.Label>GENDER</Form.Label>
                            <Form.Control disabled type="text" name='GENDER' placeholder="Mix" value={this.state.fields.GENDER} onChange={this.userinfoChange} />
                            <div className="errorMsg">{this.state.errors.GENDER}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Form.Group className="mb-3" controlId="STUDENT_NAME">
                  <Form.Label>Student name <span className='text-danger fw-bold'>*</span></Form.Label>
                  <Form.Control className='nameinform' type="text" name='STUDENT_NAME' placeholder="Enter Student name" value={this.state.fields.STUDENT_NAME} onChange={this.handleChange} />
                  <div className="errorMsg">{this.state.errors.STUDENT_NAME}</div>
                </Form.Group>
                <Row>
                  <Col>
                    <div id='IS_BFROM_AVAILABLE' className="form-check form-switch switchstyling">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="IS_BFROM_AVAILABLE"
                        value={this.state.fields.IS_BFROM_AVAILABLE}
                        checked={this.state.fields.IS_BFROM_AVAILABLE}
                        name='IS_BFROM_AVAILABLE'
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="IS_BFROM_AVAILABLE">
                        Is Student B-Form Available?
                      </label>
                    </div>
                  </Col>
                  {this.state.fields.IS_BFROM_AVAILABLE ? (
                    <Col xs={9}>
                      <Form.Group className="mb-3" controlId="STUDENT_BFORM">
                        <Form.Label>Student B-Form </Form.Label>
                        <InputMask className='form-control' id='STUDENT_BFORM' mask="99999-9999999-9" name='STUDENT_BFORM' placeholder="Enter Student B-Form" value={this.state.fields.STUDENT_BFORM} onChange={this.handleChange} />
                        <div className="errorMsg">{this.state.errors.STUDENT_BFORM}</div>
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="STUDENT_GRNO">
                      <Form.Label>Student GR No.<span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control type="number" name='STUDENT_GRNO' placeholder="Enter Student GR No" value={this.state.fields.STUDENT_GRNO} onChange={this.handleChange} />
                      <div className="errorMsg">{this.state.errors.STUDENT_GRNO}</div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="DATE_OF_ENROLMENT">
                      <Form.Label>Date of Enrolment <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control type="date" placeholder="MM/DD/YYYY" name='DATE_OF_ENROLMENT' 
                      value={this.state.fields.DATE_OF_ENROLMENT} onChange={this.handleChange}/>
                      <div className="errorMsg">{this.state.errors.DATE_OF_ENROLMENT}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="DATE_OF_BIRTH">
                      <Form.Label>Student Date of Birth <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control type="date" placeholder="MM/DD/YYYY" name='DATE_OF_BIRTH' value={this.state.fields.DATE_OF_BIRTH} onChange={this.handleChange} />
                      <div className="errorMsg">{this.state.errors.DATE_OF_BIRTH}</div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="GRADE">
                      <Form.Label>GRADE <span className='text-danger fw-bold'>*</span></Form.Label>
                      {/* <Form.Control  type="number" placeholder="Student GRADE e.g. 1, 4" value={this.state.fields.GRADE} onChange={this.handleChange} /> */}
                      <Form.Control as="select" name='GRADE' value={this.state.fields.GRADE} onChange={this.handleChange}>
                        <option value="">Select Grade</option>
                        <option value="ECE">ECE</option>
                        <option value="Kachi">Kachi</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </Form.Control>
                      <div className="errorMsg">{this.state.errors.GRADE}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="SECTION">
                      <Form.Label>SECTION <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control as="select" name='SECTION' value={this.state.fields.SECTION} onChange={this.handleChange}>
                        <option value="">Select SECTION</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                      </Form.Control>
                      {/* <Form.Control  type="text" placeholder="Student SECTION e.g. A, B" value={this.state.fields.SECTION} onChange={this.handleChange} /> */}
                      <div className="errorMsg">{this.state.errors.SECTION}</div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="STUDENT_GENDER">
                      <Form.Label>Student Gender <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control as="select" name='STUDENT_GENDER' value={this.state.fields.STUDENT_GENDER} onChange={this.handleChange}>
                        <option value="">Select Student Gender</option>
                        <option value="Boy">Boy</option>
                        <option value="Girl">Girl</option>
                      </Form.Control>
                      <div className="errorMsg">{this.state.errors.STUDENT_GENDER}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="STUDENT_RELIGION">
                      <Form.Label>Student Religion <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control as="select" name='STUDENT_RELIGION' value={this.state.fields.STUDENT_RELIGION} onChange={this.handleChange}>
                        <option value="">Select student Religion</option>
                        <option value="Islam">Islam</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Christian">Christian</option>
                        <option value="Qadiani/Ahmedi">Qadiani/Ahmedi</option>
                        <option value="Sikh">Sikh</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                      <div className="errorMsg">{this.state.errors.STUDENT_RELIGION}</div>
                    </Form.Group>
                  </Col>
                  {this.state.fields.STUDENT_RELIGION === 'Other' && ( // Check if 'Other' is selected
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="OTHER_RELIGION">
                      <Form.Label>Specify Religion:</Form.Label>
                      <Form.Control
                        type="text"
                        name='OTHER_RELIGION'
                        placeholder=""
                        value={this.state.fields.OTHER_RELIGION}
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.OTHER_RELIGION}</div>
                    </Form.Group>
                  </Col>
                )}
                  <Col>
                    <Form.Group className="mb-3" controlId="STUDENT_LANGUAGE">
                      <Form.Label>Student Mother Tongue <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control as="select" name='STUDENT_LANGUAGE' value={this.state.fields.STUDENT_LANGUAGE} onChange={this.handleChange}>
                      <option value="">Select student language</option>
                      <option value="Sindhi">Sindhi</option>
                      <option value="Urdu">Urdu</option>
                      <option value="Punjabi">Punjabi</option>
                      <option value="English">English</option>
                      <option value="Siraiki">Siraiki</option>
                      <option value="Pashto">Pashto</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                      <div className="errorMsg">{this.state.errors.STUDENT_LANGUAGE}</div>
                    </Form.Group>

                  </Col>
                  {this.state.fields.STUDENT_LANGUAGE === 'Other' && ( // Check if 'Other' is selected
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="OTHER_LANGUAGE">
                      <Form.Label>Specify Student Language:</Form.Label>
                      <Form.Control
                        type="text"
                        name='OTHER_LANGUAGE'
                        placeholder=""
                        value={this.state.fields.OTHER_LANGUAGE}
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.OTHER_LANGUAGE}</div>
                    </Form.Group>
                  </Col>
                )}
                </Row>

                <Form.Group className="mb-3" controlId="PRESENT_ADDRESS">
                  <Form.Label>Student Address </Form.Label>
                  <Form.Control type="text" name='PRESENT_ADDRESS' placeholder="Enter Present Address" value={this.state.fields.PRESENT_ADDRESS} onChange={this.handleChange} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="FATHER_GUARDIAN_NAME">
                  <Form.Label>Father/Mother/Guardian Name <span className='text-danger fw-bold'>*</span></Form.Label>
                  <Form.Control type="text" className='nameinform' name='FATHER_GUARDIAN_NAME' placeholder="Enter Father/Guardian Name" value={this.state.fields.FATHER_GUARDIAN_NAME} onChange={this.handleChange} />
                  <div className="errorMsg">{this.state.errors.FATHER_GUARDIAN_NAME}</div>
                </Form.Group>
                <Row>
                  <Col>
                    <div className="form-check form-switch switchstyling">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="IS_FATHER_GAURDIAN_CNIC_AVAILABLE"
                        value={this.state.fields.IS_FATHER_GAURDIAN_CNIC_AVAILABLE}
                        checked={this.state.fields.IS_FATHER_GAURDIAN_CNIC_AVAILABLE}
                        name='IS_FATHER_GAURDIAN_CNIC_AVAILABLE'
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="IS_FATHER_GAURDIAN_CNIC_AVAILABLE">
                        Is Father/Mother/Guardian CNIC Available?
                      </label>
                    </div>
                  </Col>
                  {this.state.fields.IS_FATHER_GAURDIAN_CNIC_AVAILABLE ? (
                    <Col xs={9}>
                      <Form.Group className="mb-3" controlId="FATHER_GUARDIAN_CNIC">
                        <Form.Label>Father/Mother/Guardian CNIC</Form.Label>
                        <InputMask className='form-control' mask="99999-9999999-9" name='FATHER_GUARDIAN_CNIC' placeholder="Enter Father/Guardian CNIC" value={this.state.fields.FATHER_GUARDIAN_CNIC} onChange={this.handleChange} />
                        <div className="errorMsg">{this.state.errors.FATHER_GUARDIAN_CNIC}</div>
                      </Form.Group>
                    </Col>
                  ) : null}

                </Row>

                <Form.Group className="mb-3" controlId="FATHER_GUARDIAN_CONTACT_NO">
                  <Form.Label>Father/Mother/Guardian Phone No. </Form.Label>
                  <InputMask className='form-control' mask="0399-9999999" name='FATHER_GUARDIAN_CONTACT_NO' placeholder="Enter Father/Guardian Contact No." value={this.state.fields.FATHER_GUARDIAN_CONTACT_NO} onChange={this.handleChange} />
                  <div className="errorMsg">{this.state.errors.FATHER_GUARDIAN_CONTACT_NO}</div>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="GUARDIAN_RELATION">
                      <Form.Label>Guardian Relation <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control as="select" name='GUARDIAN_RELATION' value={this.state.fields.GUARDIAN_RELATION} onChange={this.handleChange}>
                      <option value="">Select Parent/Gaurdian Relation</option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Brother">Brother</option>
                        <option value="Sister">Sister</option>
                        <option value="Uncle">Uncle</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                      <div className="errorMsg">{this.state.errors.GUARDIAN_RELATION}</div>
                    </Form.Group>
                  </Col>
                  {this.state.fields.GUARDIAN_RELATION === 'Other' && ( // Check if 'Other' is selected
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="OTHER_GUARDIAN_RELATION">
                      <Form.Label>Specify Gaurdian Relation:</Form.Label>
                      <Form.Control
                        type="text"
                        name='OTHER_GUARDIAN_RELATION'
                        placeholder=""
                        value={this.state.fields.OTHER_GUARDIAN_RELATION}
                        onChange={this.handleChange}
                      />
                      <div className="errorMsg">{this.state.errors.OTHER_GUARDIAN_RELATION}</div>
                    </Form.Group>
                  </Col>
                )}
                  <Col>
                    <Form.Group className="mb-3" controlId="STUDENT_WITH_DISABILITY">
                    <Form.Label>Is student Disable? <span className='text-danger fw-bold'>*</span></Form.Label>
                      <Form.Control as="select" name='STUDENT_WITH_DISABILITY' value={this.state.fields.STUDENT_WITH_DISABILITY} onChange={this.handleChange}>
                        <option value="">Select Option (Yes/No)</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Control>
                      <div className="errorMsg">{this.state.errors.STUDENT_WITH_DISABILITY}</div>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3" controlId="REMARKS">
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control as="textarea" name='REMARKS' placeholder="Enter Remarks" value={this.state.fields.REMARKS} onChange={this.handleChange} />
                  {/* <div className="errorMsg">{this.state.errors.REMARKS}</div> */}
                </Form.Group>

                <Row>
                  <Col>
                    <Button size='lg' className='d-block' variant="primary" type="submit">
                      Submit
                    </Button>
                    {/* <Button  onClick={() => { this.addstudent() }} className='d-block' variant="primary" type="submit">
                            Submit
                        </Button> */}
                  </Col>

                </Row>
              </Form>
            </div>
          )}

        </Container>
      </div>
    );
  }
}

export default AddStudent;