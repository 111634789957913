import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Button  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck, faDownload  } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

function DistrictWiseStatus() {
    const [enrolData, setEnrolData] = useState([]);
    const [filteredEnrolData, setFilteredEnrolData] = useState([]);
    const [itemsPerPage] = useState(10);
    const [mode, setMode] = useState('loading');
    const [Totals, setTotals] = useState([]);


    useEffect(() => {
        // let semiscode = localStorage.getItem('userin');
        fetch(`https://stddataapi.rsu-sindh.gov.pk/api/districtWiseCount`)
        .then((response) => response.json())
        .then((data) => {
          setEnrolData(data.districtWiseCount || []);
          setFilteredEnrolData(data.districtWiseCount || []);
          localStorage.setItem('districtWiseCount', JSON.stringify(data.districtWiseCount));
          setMode('online');
        })
        .catch(() => {
            setMode('offline');
            let districtWiseCount = localStorage.getItem('districtWiseCount');
            setEnrolData(JSON.parse(districtWiseCount) || []);
            setFilteredEnrolData(JSON.parse(districtWiseCount) || []);
          });
    }, [itemsPerPage]);


    const calculateTotals = () => {
        return {

          totalSchools: filteredEnrolData.reduce((sum, item) => sum + Number(item.total_school_selected), 0),

          submittedSchools: filteredEnrolData.reduce((sum, item) => sum + Number(item.total_school_submitted), 0),
          totalEnrollment: filteredEnrolData.reduce((sum, item) => sum + Number(item.total_previous_enrollment), 0),
          submittedEnrollment: filteredEnrolData.reduce((sum, item) => sum + Number(item.current_data_submitted), 0),
        };
      };
      const totals = calculateTotals();

    return (
        <div>
            <Container>

                <div>
                    {mode === 'offline' ? (
                        <div className="alert alert-warning" role="alert">
                            You are offline or there is an issue with the connection!
                        </div>
                    ) : null}
                </div>

                <div className="row" style={{ marginTop: "120px" }}>
                    <div className="col-md-8 col-sm-8">
                        <h4 className="mt-3 mb-2">
                            <FontAwesomeIcon icon={faListCheck} /> Overall District Wise Progress
                        </h4>
                    </div>
                   
{/*                  
                    <div className="col-md-4 col-sm-4 pull-right">
                        <Button variant="primary" className="mb-2" onClick={handleDownloadExcel}>
                            Export in Excel <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </div> */}
                </div>

                {mode === 'loading' ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        
                    </div>

                   
                   
                   
                ) : (
                    
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th className="text-center">SNO.</th>
                                <th>District</th>
                                <th className="text-center bg-default">Data Collected By</th>
                                <th className="text-center bg-success">Total No. of <br/> Schools</th>
                                <th className="text-center bg-warning">Submitted <br/>  Schools</th>
                                <th className="text-center bg-danger">Remaining <br/> Schools %</th>
                                <th className="text-center bg-success">Total Enrollment <br/> as per ASC 2022-23</th>
                                <th className="text-center bg-warning">Enrollment <br/> Submitted</th>
                                <th className="text-center bg-danger">Remaining <br/> Enrollment %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEnrolData.map((totaldisttwisecount, index) => (
                                <tr key={totaldisttwisecount.district}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{totaldisttwisecount.district}</td>
                                    <td> {totaldisttwisecount.name} 
                                     <small> - {totaldisttwisecount.designation} - {totaldisttwisecount.LSU_tag} </small></td>
                                    <td className="text-center">{totaldisttwisecount.total_school_selected}</td>
                                    <td className="text-center">{totaldisttwisecount.total_school_submitted}</td>
                                    <td className="text-center">
                                        {(((totaldisttwisecount.total_school_selected - totaldisttwisecount.total_school_submitted) / totaldisttwisecount.total_school_selected) * 100).toFixed(2)}%
                                    </td>

                                    <td className="text-center">{totaldisttwisecount.total_previous_enrollment}</td>
                                    <td className="text-center">{totaldisttwisecount.current_data_submitted}</td>

                                    <td className="text-center">
                                        {/* {((totaldisttwisecount.current_data_submitted / totaldisttwisecount.total_previous_enrollment) * 100).toFixed(2)}% */}
                                        {(((totaldisttwisecount.total_previous_enrollment - totaldisttwisecount.current_data_submitted) / totaldisttwisecount.total_previous_enrollment) * 100).toFixed(2)}%

                                    </td>

                                </tr>
                            ))}

                        <tr className='fw-bold'>
                            <td colSpan="3" className='text-right'>TOTAL:</td>
                            <td className="text-center">{totals.totalSchools}</td>
                            <td className="text-center">{totals.submittedSchools}</td>
                            <td className="text-center">
                                {totals.totalSchools > 0
                                ? (((totals.totalSchools - totals.submittedSchools) / totals.totalSchools) * 100).toFixed(2)
                                : 0}%
                            </td>
                            <td className="text-center">{totals.totalEnrollment}</td>
                            <td className="text-center">{totals.submittedEnrollment}</td>
                            <td className="text-center">
                                {totals.totalEnrollment > 0
                                ? (((totals.totalEnrollment - totals.submittedEnrollment) / totals.totalEnrollment) * 100).toFixed(2)
                                : 0}%
                            </td>
                        </tr>
                             
                        </tbody>
                       
                    </Table>
                    
                )}
         
                  
          
            </Container>
        </div>
    );
}

export default DistrictWiseStatus;
