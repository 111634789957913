
import './App.css';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Login from './screens/Login';
import Home from './screens/Home';
import AddStudent from './screens/AddStudent';
import StudentList from './screens/StudentList';
import UserList from './screens/UserList';
import Registration from './screens/Registration';
// import NavbarMenu from './screens/NavbarMenu';
import Logout from './screens/Logout';
import Protected from './screens/Protected';
import UploadBulkData from './screens/UploadBulkData';
import UpdateStudent from './screens/UpdateStudent';
import SchoolWiseEnrolCount from './screens/EnrolmentCount';
import UserProfile from './screens/UserProfile';
import DistrictRemainingSchools from './screens/DistrictRemainingSchools';


function App() {

  let { ID } = useParams();
  const isLoggedIn = localStorage.getItem('userin');

  return (

    <Router>
     


      <Routes>
        {/* <Protected path="/" component={Home} /> */}

        <Route exact path='/' element={<Protected/>}>
            <Route exact path='/' element={<Home/>}/>
        </Route>
        <Route path='/addstudent' element={<Protected/>}>
            <Route path='/addstudent' element={<AddStudent/>}/>
        </Route>
        <Route path='/UpdateStudent/:ID' element={<Protected/>}>
            <Route path='/UpdateStudent/:ID' element={<UpdateStudent/>}/>
        </Route>

        {/* <Route path='/deletestudent/:ID' element={<Protected/>}>
            <Route path='/deletestudent/:ID' element={<UpdateStudent/>}/>
        </Route> */}

        <Route path='/studentlist' element={<Protected/>}>
            <Route path='/studentlist' element={<StudentList/>}/>
        </Route>

        <Route path='/DistrictRemainingSchools' element={<Protected/>}>
            <Route path='/DistrictRemainingSchools' element={<DistrictRemainingSchools/>}/>
        </Route>
        
        <Route path='/userlist' element={<Protected/>}>
            <Route path='/userlist' element={<UserList/>}/>
        </Route>

        <Route path='/profile' element={<Protected/>}>
            <Route path='/profile' element={<UserProfile/>}/>
        </Route>

        {/* <Route path='/schoolwiseenrolcount' element={<Protected/>}>
            <Route path='/schoolwiseenrolcount' element={<SchoolWiseEnrolCount/>}/>
        </Route> */}

        <Route path='/uploadbulkdata' element={<Protected/>}>
            <Route path='/uploadbulkdata' element={<UploadBulkData/>}/>
        </Route>
        {/* <Route path="/addstudent" element={<DataCollect/>} /> */}
        {/* <Route path="/studentlist" element={<DataList />} /> */}
        
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/logout" element={<Logout />} />

      </Routes>


    </Router>



  );
}

export default App;
