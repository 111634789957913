export default function swDev() {

    function determineAppServerKey() {
        const vapidPublicKey =
            "BJthRQ5myDgc7OSXzPCMftGw-n16F7zQBEN7EUD6XxcfTTvrLGWSIG7y_JxiWtVlCFua0S8MTB5rPziBqNx1qIo";
        return urlBase64ToUint8Array(vapidPublicKey);
    }

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.register('/sw.js') // Make sure the path to sw.js is correct
    //       .then((registration) => {
    //         console.log('Service Worker registered with scope:', registration.scope);
    //       })
    //       .catch((error) => {
    //         console.error('Service Worker registration failed:', error);
    //       });
    //   }
      
    const swURL = `${process.env.PUBLIC_URL}/sw.js`;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(swURL)
    .then(registration => {
        // console.warn("response", response.scope);
        // console.log('Service Worker registered with scope:', registration.scope);
        return registration.pushManager.getSubscription()
      .then(function (subscription) {
        registration.pushManager.subscribe({
         userVisibleOnly: true,
         applicationServerKey: determineAppServerKey()
       })
     })
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}


    // let swURL = `${process.env.PUBLIC_URL}/sw.js`;
    // console.log('Service Worker:', swURL);
    // navigator.serviceWorker.register(swURL).then((response) => {
    //     console.warn("response", response.scope);
    //     return response.pushManager.getSubscription()
    //     .then(function (subscription) {
    //        response.pushManager.subscribe({
    //         userVisibleOnly: true,
    //         applicationServerKey: determineAppServerKey()
    //       })
    //     })

    // });


}


