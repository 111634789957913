import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Form, Pagination, Button, Alert  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faListCheck, faDownload, faTrash  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import NavbarMenu from './NavbarMenu';

function StudentList() {
    const [studentData, setStudentData] = useState([]);
    const [filteredStudentData, setFilteredStudentData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [mode, setMode] = useState('loading');
    const [isDeleted, setIsDeleted] = useState(false);
    const [error, setError] = useState([]);

    useEffect(() => {
        fetchStudentData();
    }, [itemsPerPage]);


    // const fetchStudentData = () => {
    //     let semiscode = JSON.parse(localStorage.getItem('userin'));
    //     fetch(`https://stddataapi.rsu-sindh.gov.pk/api/getstudentlist/${semiscode}?limit=${itemsPerPage}`)
    //         .then((response) => response.json())
    //         .then((data) => {
    //             if (data.studentlist && data.studentlist.length > 0) {
    //                 setStudentData(data.studentlist);
    //                 setFilteredStudentData(data.studentlist);
    //                 localStorage.setItem('studentlist', JSON.stringify(data.studentlist));
    //                 setMode('online');
    //             } else {
    //                 setMode('offline');
    //                 let collection = localStorage.getItem('studentlist');
    //                 setStudentData(JSON.parse(collection) || []);
    //                 setFilteredStudentData(JSON.parse(collection) || []);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching student data:', error);
    //             setMode('offline');
    //             let collection = localStorage.getItem('studentlist');
    //             setStudentData(JSON.parse(collection) || []);
    //             setFilteredStudentData(JSON.parse(collection) || []);
    //             setError(true);
    //         });
        
    // };

    const fetchStudentData = () => {
        let semiscode = JSON.parse(localStorage.getItem('userin'));
        fetch(`https://stddataapi.rsu-sindh.gov.pk/api/getstudentlist/${semiscode}?limit=${itemsPerPage}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.studentlist && data.studentlist.length > 0) {
                    setStudentData(data.studentlist);
                    setFilteredStudentData(data.studentlist);
                    try {
                        localStorage.setItem('studentlist', JSON.stringify(data.studentlist));
                        setMode('online');
                    } catch (error) {
                        console.error('Error storing data in localStorage:', error);
                        setMode('offline');
                    }
                } else {
                    setMode('offline');
                    let collection = localStorage.getItem('studentlist');
                    setStudentData(JSON.parse(collection) || []);
                    setFilteredStudentData(JSON.parse(collection) || []);
                }
            })
            .catch((error) => {
                console.error('Error fetching student data:', error);
                setMode('offline');
                let collection = localStorage.getItem('studentlist');
                setStudentData(JSON.parse(collection) || []);
                setFilteredStudentData(JSON.parse(collection) || []);
                // Optionally, display an error message to the user
                // You can set a state variable for displaying an error message and handle it in your UI
                setError(true);
            });
    };
    
    
    
    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    
        // Check if studentData is not null or undefined
        if (studentData) {
            const filteredData = studentData.filter((student) => {
                const studentName = (student.STUDENT_NAME || '').toLowerCase();
                const grNo = (student.STUDENT_GRNO || '').toString().toLowerCase(); // Assuming GR_NO is a number
                const semisCode = (student.SEMIS_CODE || '').toString().toLowerCase(); // Assuming SEMIS_CODE is a number
    
                return studentName.includes(query) || grNo.includes(query) || semisCode.includes(query);
            });
    
            setFilteredStudentData(filteredData);
            setCurrentPage(1);
        }
    };
    

    // const handleSearchChange = (event) => {
    //     const query = event.target.value.toLowerCase();
    //     setSearchQuery(query);
    
    //     // Check if studentData is not null or undefined
    //     if (studentData) {

    //         const filteredData = studentData.filter((student) => {
    //             const studentName = student.STUDENT_NAME || '';
    //             return studentName.toLowerCase().includes(query);
    //         });
    
    //         setFilteredStudentData(filteredData);
    //         setCurrentPage(1); 
    //     }
    // };


    
    const handleDelete = (ID) => {
            fetch(`https://stddataapi.rsu-sindh.gov.pk/api/studentdelete/${ID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
              if(data.status == 404){
                alert('Student not found!')
              }else if(data.status == 200){
                alert('Student deleted successfully!')
                fetchStudentData();
              }else{
                alert('Something went wrong!')
              }
            })
            .catch(error => {
              console.error('Error:', error);
            });
    };

    // if (isDeleted) {
    //     // Optionally, you can redirect to a different page or show a success message
    //     alert('Student delete successfully!');
    // }


    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredStudentData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');
        XLSX.writeFile(workbook, 'students.xlsx');
    };



    const totalPages = Math.ceil(filteredStudentData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedStudents = filteredStudentData.slice(startIndex, endIndex);

    const renderPaginationItems = () => {
        const MAX_VISIBLE_PAGES = 3;
        const items = [];
        for (let i = 1; i <= Math.min(totalPages, 10); i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

    // Show Ellipsis if there are more than 16 total pages
    if (totalPages > 10) {
        items.push(<Pagination.Ellipsis key="ellipsis-after-16" />);
    }

    for (let i = Math.max(totalPages - MAX_VISIBLE_PAGES, 11); i <= totalPages; i++) {
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => setCurrentPage(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    return items;
};
    let adminuser = JSON.parse(localStorage.getItem('userin'));
    return (
        <div>
             <NavbarMenu />
            <Container style={{ marginTop: "120px" }}>
                <div>
                    {mode === 'offline' ? (
                        <div className="alert alert-warning" role="alert">
                            You are offline or there is an issue with the connection!
                        </div>
                    ) : null}
                </div>

                <div className="row">
                    <div className="col-md-4 col-sm-4">
                        <h4 className="mt-3 mb-2">
                            <FontAwesomeIcon icon={faListCheck} /> Student List
                        </h4>
                    </div>
                   
                    <div className="col-md-5 col-sm-5">
                        <Form.Group className="mb-3" controlId="uc">
                            <Form.Control
                                type="text"
                                placeholder="Search by student name..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 col-sm-3 pull-right">
                        <Button variant="primary" className="mb-2" onClick={handleDownloadExcel}>
                            Export in Excel <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </div>
                </div>

                {mode === 'loading' ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        
                    </div>

                   
                   
                   
                ) : (
                    
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>SEMIS CODE</th>
                                <th>SCHOOL NAME</th>
                                <th>STUDENT NAME</th>
                                <th>GR NO.</th>
                                <th>GRADE - SECTION</th>
                                <th colSpan={2} className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedStudents.map((student, index) => (
                                <tr key={student.ID}  id={student.ID}>
                                    <td>{index + 1}</td>
                                    <td>{student.SEMIS_CODE}</td>
                                    <td>{student.SCHOOL_NAME}</td>
                                    <td>{student.STUDENT_NAME}</td>
                                    <td>{student.STUDENT_GRNO}</td>
                                    <td>{student.GRADE} - {student.SECTION}</td>
                                    <td className="text-center">
                                        <Link to={`/UpdateStudent/${student.ID}`}>
                                            Edit <FontAwesomeIcon icon={faPenToSquare} />
                                        </Link>
                                    </td>
                                    {adminuser == 123456789 && ( 
                                    <td className="text-center">
                                        <button onClick={() => handleDelete(student.ID)} className="btn btn-danger">
                                            Delete <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                    )}
                                </tr>
                            ))}
                            
                        </tbody>
                       
                    </Table>
                    
                )}
         
                <div className="d-flex justify-content-center">
                    <Pagination>
                        {currentPage > 1 && <Pagination.First onClick={() => setCurrentPage(1)} />}
                        {currentPage > 1 && <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />}
                        {/* Render pagination items */}
                        {renderPaginationItems()}

                        {/* Add First and Last Page Navigation if needed */}
                        {currentPage < totalPages && <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />}
                        {currentPage < totalPages && <Pagination.Last onClick={() => setCurrentPage(totalPages)} />}
                    </Pagination>
                </div>
                    {/* <Pagination>
                        {totalPages > 15 && <Pagination.First onClick={() => setCurrentPage(1)} />}
                        {totalPages > 15 && <Pagination.Last onClick={() => setCurrentPage(totalPages)} />}

                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => setCurrentPage(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        {totalPages > 15 && <Pagination.Ellipsis />}
                        {totalPages > 15 && <Pagination.First onClick={() => setCurrentPage(1)} />}
                        {totalPages > 15 && <Pagination.Last onClick={() => setCurrentPage(totalPages)} />}

                    </Pagination> */}
          
            </Container>
        </div>
    );
}

export default StudentList;
