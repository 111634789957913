import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Form, Pagination, Button  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faListCheck, faDownload  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import NavbarMenu from './NavbarMenu';

function UserList() {
    const [userData, setUserData] = useState([]);
    const [filteredUserData, setFilteredUserData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [mode, setMode] = useState('loading');


    useEffect(() => {
        // let semiscode = localStorage.getItem('userin');
        fetch(`https://stddataapi.rsu-sindh.gov.pk/api/user`)
            .then((response) => response.json())
            .then((data) => {
                setUserData(data.users || []); 
                setFilteredUserData(data.users || []); 
                localStorage.setItem('users', JSON.stringify(data.users));
                setMode('online');
            })
            .catch(() => {
                setMode('offline');
                let userlist = localStorage.getItem('users');
                setUserData(JSON.parse(userlist) || []); 
                setFilteredUserData(JSON.parse(userlist) || []); 
            });
    }, [itemsPerPage]);


    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    

        if (userData) {

            const filteredData = userData.filter((user) => {
                const UserName = user.name || '';
                return UserName.toLowerCase().includes(query);
            });
    
            setFilteredUserData(filteredData);
            setCurrentPage(1); 
        }
    };
    

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredUserData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');
        XLSX.writeFile(workbook, 'students.xlsx');
    };

    const totalPages = Math.ceil(filteredUserData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedUsers = filteredUserData.slice(startIndex, endIndex);

    const renderPaginationItems = () => {
        const MAX_VISIBLE_PAGES = 3;
        const items = [];
        for (let i = 1; i <= Math.min(totalPages, 10); i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

    // Show Ellipsis if there are more than 16 total pages
    if (totalPages > 10) {
        items.push(<Pagination.Ellipsis key="ellipsis-after-16" />);
    }

    for (let i = Math.max(totalPages - MAX_VISIBLE_PAGES, 11); i <= totalPages; i++) {
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => setCurrentPage(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    return items;
};


    return (
        <div>
            <NavbarMenu />
            <Container>

                <div>
                    {mode === 'offline' ? (
                        <div className="alert alert-warning" role="alert">
                            You are offline or there is an issue with the connection!
                        </div>
                    ) : null}
                </div>

                <div className="row" style={{ marginTop: "120px" }}>
                    <div className="col-md-4 col-sm-4">
                        <h4 className="mt-3 mb-2">
                            <FontAwesomeIcon icon={faListCheck} /> Student List
                        </h4>
                    </div>
                   
                    <div className="col-md-5 col-sm-5">
                        <Form.Group className="mb-3" controlId="uc">
                            <Form.Control
                                type="text"
                                placeholder="Search by name..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 col-sm-3 pull-right">
                        <Button variant="primary" className="mb-2" onClick={handleDownloadExcel}>
                            Export in Excel <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </div>
                </div>

                {mode === 'loading' ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        
                    </div>

                   
                   
                   
                ) : (
                    
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th>SEMIS CODE</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Contact</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedUsers.map((users, index) => (
                                <tr key={users.id}>
                                    <td>{users.semis_code}</td>
                                    <td>{users.name}</td>
                                    <td>{users.designation}</td>
                                    <td>{users.contact_no}</td>
                                    <td>{users.customized == 1 ? 'Registered' : 'Unregistered'}</td>
                                    {/* <td className="text-center">
                                        <Link to={`/UpdateStudent/${users.ID}`}>
                                            Edit <FontAwesomeIcon icon={faPenToSquare} />
                                        </Link>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                       
                    </Table>
                    
                )}
         
                <div className="d-flex justify-content-center">
                    <Pagination>
                        {currentPage > 1 && <Pagination.First onClick={() => setCurrentPage(1)} />}
                        {currentPage > 1 && <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />}
                        {/* Render pagination items */}
                        {renderPaginationItems()}

                        {/* Add First and Last Page Navigation if needed */}
                        {currentPage < totalPages && <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />}
                        {currentPage < totalPages && <Pagination.Last onClick={() => setCurrentPage(totalPages)} />}
                    </Pagination>
                </div>
                    {/* <Pagination>
                        {totalPages > 15 && <Pagination.First onClick={() => setCurrentPage(1)} />}
                        {totalPages > 15 && <Pagination.Last onClick={() => setCurrentPage(totalPages)} />}

                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => setCurrentPage(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        {totalPages > 15 && <Pagination.Ellipsis />}
                        {totalPages > 15 && <Pagination.First onClick={() => setCurrentPage(1)} />}
                        {totalPages > 15 && <Pagination.Last onClick={() => setCurrentPage(totalPages)} />}

                    </Pagination> */}
          
            </Container>
        </div>
    );
}

export default UserList;
