import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Image, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
// import StyledAlert from './StyledAlert';
// import NavbarMenu from './NavbarMenu';

// class Login extends Component {
function Login() {

    const navigate = useNavigate();

    const [semis_code, setSemisCode] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    // const [alertMessage, setAlertMessage] = useState('');

    const loginuser = () => {
        setIsLoading(true); // Set loading to true on button click
        // fetch(`http://127.0.0.1:8000/api/userlogin?semis_code=`+this.state.semis_code+`&password=`+this.state.password)
        fetch(`https://stddataapi.rsu-sindh.gov.pk/api/userlogin?semis_code=${semis_code}&password=${password}`)
            .then((data) => {
                data.json().then((resp) => {
                    if (resp.status === 'success' && resp.customized.customized == 0) {
                        localStorage.setItem('userin', JSON.stringify(resp.token.token));
                        localStorage.setItem('usertypename', JSON.stringify(resp.school_name.school_name));
                        navigate('/registration');
                    } else if(resp.status === 'success' && resp.customized.customized == 1){
                        localStorage.setItem('userin', JSON.stringify(resp.token.token));
                         navigate('/');
                    }else {
                        alert('Unauthorized User!');
                        setIsLoading(false);
                    }
                });
            });
    };


    return (

        <Container>
                
            <Row className="justify-content-md-center">
                <Col md="auto">
               
                <Col md="auto ">
                    <div className='text-center d-flex justify-content-center'>
                    <Image width={100} className='mt-3 text-center' src="./select_logo.png" />
                    </div>
                    </Col>
                    {/* <h2 className='text-center text-bold'>SELECT</h2> */}

                <h5 className='text-center'>
                <strong> Sindh Early Learning Enhancement through Classroom Transformation,</strong> <br /> <small> Reform Support Unit, SE&LD <br /> Government of Sindh </small> </h5>

                <h4 className='mt-3 mb-3 text-center'>Login</h4>
                <Form className="mb-3" onSubmit={loginuser}>
                    <Row className="mb-3">
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" required name="semis_code" placeholder="Enter SEMIS Code as username" onChange={(e) => setSemisCode(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="designation">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" required name="password" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        {/* <Button onClick={()=>{this.login()}} className='d-block' variant="success"> */}

                    </Row>
                    <Row className="justify-content-md-center">
                    <Col md="auto">
                                {/* Conditional rendering of login button or loader */}
                                {isLoading ? (
                                    <Button  disabled variant="success">
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        Loading...
                                    </Button>
                                ) : (
                                    <Button onClick={loginuser} className='d-block' variant="success">
                                        Login
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        
                </Form>
                </Col>
            </Row>
        </Container>

    );

}

export default Login;
