import React, { useState, useCallback } from 'react';
import { Container, Form, Button, Row, Col, Image, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom"
import InputMask from 'react-input-mask';

const Registration = () => {

  const [fields, setFields] = useState({
    name: '',
    designation: '',
    contact_no: '',
    password: '',
    is_nearby_place_available: false,
    type_of_place: '',
    other_place_specified: '',
    distrance_in_meters: '',
    school_shift: '',
    space_enough_for_children: '',
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const userinfoChange = (event) => {
    const { name, value, checked } = event.target;
  
    setFields({ ...fields, [event.target.name]: event.target.value });

    setFields(prevFields => ({
      ...prevFields,
      [name]: name === 'is_nearby_place_available' ? checked : value,
    }));

    if (name === 'is_nearby_place_available' && !checked) {
      setFields(prevFields => ({
        ...prevFields,
        type_of_place : '',
        other_place_specified : '',
        distrance_in_meters : '',
        school_shift : '',
        space_enough_for_children : '',
      }));
    }

  };



  const updateuser = (event) => {
    event.preventDefault();

    let semiscode = JSON.parse(localStorage.getItem('userin'));
    
    if (validateForm()) {
      setIsLoading(true);
      fetch(`https://stddataapi.rsu-sindh.gov.pk/api/updateuser/${semiscode}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(fields)
      }).then((result) => {
        result.json().then((resp) => {
          setIsLoading(false);
          if (resp.status === 200) {
            alert('Record updated successfully!');
            // localStorage.setItem('userin', JSON.stringify(resp.token.token));
            navigate('/');
          } else if (resp.status === 404) {
            alert('User not found!');
          }
          else if (resp.status === 200) {
            alert('Error updating record!');
          }
        })
      })
    }
  }

  const validateForm = () => {

    // let fields = fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter your Name.";
    }
    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "*Please enter valid Name (only contains characters).";
      }
    }
    if (!fields["designation"]) {
      formIsValid = false;
      errors["designation"] = "*Please enter your designation.";
    }
    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
    if (!fields["contact_no"]) {
      formIsValid = false;
      errors["contact_no"] = "*Please enter your Phone no.";
    }


    if (typeof fields["contact_no"] !== "undefined") {
      if (!fields["contact_no"].match(/^(\d{4}-\d{7}|\d{12})$/)) {
        formIsValid = false;
        errors["contact_no"] = "*Please enter valid Phone no.";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter password.";
    }

    setErrors(errors);
    return formIsValid;

  }

  let typeofuser = JSON.parse(localStorage.getItem('usertypename'));

  return (
    <Container>
      <Row className="justify-content-md-center">
      <Col md="8">
            <div className='text-center d-flex justify-content-center'>
              <Image width={100} className='mt-3 text-center' src="./select_logo.png" />
            </div>
            <h5 className='text-center'>
            <strong> Sindh Early Learning Enhancement through Classroom Transformation,</strong> <br /> <small> Reform Support Unit, SE&LD <br /> Government of Sindh </small> </h5>
          <h4 className='mt-4 mb-3 text-center'> Registration </h4>
          </Col>
        <Col md="8">
          <Form className='mb-3' onSubmit={updateuser}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control className='nameinform' type="text" name='name' placeholder="Xyz..." value={fields.name} onChange={userinfoChange} />
                  <div className="errorMsg">{errors.name}</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="designation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control className='nameinform' type="text" name='designation' placeholder="Head Teacher" value={fields.designation} onChange={userinfoChange} />
                  <div className="errorMsg">{errors.designation}</div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="contact_no">
                  <Form.Label>Contact No.</Form.Label>
                  <InputMask className='form-control' mask="0399-9999999" type="text" name='contact_no' placeholder="03150000000" value={fields.contact_no} onChange={userinfoChange} />
                  <div className="errorMsg">{errors.contact_no}</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" name='password' placeholder="Password" value={fields.password} onChange={userinfoChange} />
                  <div className="errorMsg">{errors.password}</div>
                </Form.Group>
              </Col>
            </Row>
            {typeofuser != "LSU" && (
            <Row>
              <Col>
                <h5 className='mt-4 mb-3 text-decoration'> Relocation Related Information During School Construction Period </h5>
              </Col>
            </Row>
  )}
  {typeofuser != "LSU" && (
            <Row>
              <Col>
                <div id='is_nearby_place_available' className="form-check form-switch switchstyling pl-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="is_nearby_place_available"
                    value={fields.is_nearby_place_available}
                    checked={fields.is_nearby_place_available}
                    name='is_nearby_place_available'
                    onChange={userinfoChange}
                  />
                  <label className="form-check-label fw-bold" htmlFor="is_nearby_place_available">
                    Is there space for student in case of Construction of school?
                  </label>
                  <div className="errorMsg">{errors.is_nearby_place_available}</div>
                </div>

              </Col>
            </Row>
   )}

            {fields.is_nearby_place_available &&  (
              <Row className='mb-3'>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3" controlId="type_of_place">
                    <Form.Label>Type of place:	</Form.Label>
                    <Form.Control as="select" name='type_of_place' value={fields.type_of_place} onChange={userinfoChange}>
                      <option value="">Select Type of place</option>
                      <option value="Existing School Premises">Existing School Premises</option>
                      <option value="Near by School">Near by School</option>
                      <option value="Near by community centre">Near by community centre</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                    <div className="errorMsg">{errors.type_of_place}</div>
                  </Form.Group>
                </Col>
                {fields.type_of_place === 'Other' && ( // Check if 'Other' is selected
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="other_place_specified">
                      <Form.Label>Specify Place:</Form.Label>
                      <Form.Control
                        type="text"
                        name='other_place_specified'
                        placeholder=""
                        value={fields.other_place_specified}
                        onChange={userinfoChange}
                      />
                      <div className="errorMsg">{errors.other_place_specified}</div>
                      <small>Alternative place should not be (i) Private House, (ii) Building with Weak structure,
                        (iii) Tent, (IV) Open air arrangement, (V) Busy bus stop
                      </small>
                    </Form.Group>
                  </Col>
                )}
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3" controlId="distrance_in_meters">
                    <Form.Label>Distance in meters from origion (current school)?</Form.Label>
                    <Form.Control type="number" name='distrance_in_meters' placeholder="e.g. 5" value={fields.distrance_in_meters} onChange={userinfoChange} />
                    <div className="errorMsg">{errors.distrance_in_meters}</div>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3" controlId="school_shift">
                    <Form.Label>School Shift (After relocation)? </Form.Label>
                    <Form.Control as="select" name='school_shift' value={fields.school_shift} onChange={userinfoChange}>
                      <option value="">Select School Shift</option>
                      <option value="Morning">Morning</option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Both">Both</option>
                    </Form.Control>
                    <div className="errorMsg">{errors.school_shift}</div>
                  </Form.Group>
                </Col>

                <Col xs={12} md={6}>
                  <Form.Group className="mb-3" controlId="space_enough_for_children">
                    <Form.Label>Is enough space organize all the students of the School?</Form.Label>
                    <Form.Control as="select" name='space_enough_for_children' value={fields.space_enough_for_children} onChange={userinfoChange}>
                      <option value="">Select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Control>
                    <div className="errorMsg">{errors.space_enough_for_children}</div>
                  </Form.Group>
                </Col>

              </Row>

              )}


            <Row className='justify-content-center mt-3'>
              <Col className='text-center'>
                {/* Conditional rendering of sign-up button or loader */}
                {isLoading ? (
                  <Button size="lg" disabled variant="success">
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    Signing Up...
                  </Button>
                ) : (
                  <Button size="lg" className='d-block' variant="success" type="submit">
                    Submit
                  </Button>
                )}
              </Col>

            </Row>

          </Form>
        </Col>
      </Row>

    </Container>
  );
}

export default Registration;
