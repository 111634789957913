import React, { Component } from 'react';
import { Navigate, Outlet } from "react-router-dom";


const Protected = () => {
    const auth = localStorage.getItem('userin');
    return auth ? <Outlet /> : <Navigate to="/login" />;

    // <Route
    // {...rest}
    // render={(props)=>
    //     localStorage.getItem('userin') ? (
    //         <Cmp {...props} />
    //     ):
    //     <Navigate to="/login"/>
    // }
    
    // />

}


export default Protected;